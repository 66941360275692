const SkeletonPosition = () => {
    return (
        <div className='skeleton-posiciones-activas'>
            <div className='skeleton-posiciones-activas__text'>
                <h6 className='skeleton-posiciones-activas__text--title skeleton-posiciones-activas__loading-animation'></h6>
                <p className='skeleton-posiciones-activas__text--location skeleton-posiciones-activas__loading-animation'></p>
            </div>
            <span className='skeleton-posiciones-activas__arrow  skeleton-posiciones-activas__loading-animation'></span>
        </div>);
};

export default SkeletonPosition;
