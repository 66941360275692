import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import './autocomplete.scss';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';
import { useTranslation } from 'react-i18next';

const GooglePlacesAutocomplete = ({ defaultValue, country, disabled, onSelectResult }) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState('');

    useEffect(() => {
        if (defaultValue) {
            setSelected(defaultValue);
        }
    }, [country, defaultValue, disabled]);

    const handleSelectPlace = (place) => {
        if (place.length >= 5) onSelectResult(place);
        setSelected(place);
    };

    const handleSelect = async (location, placeId) => {
        const [place] = await geocodeByPlaceId(placeId);
        setSelected(place.formatted_address);
        onSelectResult(place.formatted_address);
    };

    return (
        <PlacesAutocomplete
            value={selected}
            searchOptions={{
                types: ['postal_code'],
                language: 'es',
                componentRestrictions: {
                    country: [country]
                }
            }}
            onChange={handleSelectPlace}
            onSelect={handleSelect}
            onError={(status, clearSuggestions) => {
                if (status === 'ZERO_RESULTS') {
                    toast.warning(t('vende.form.errors.noresults'));
                } else {
                    toast.error('Google Maps API returned error with status: ', status);
                }

                clearSuggestions();
            }}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className='places-search'>
                    <input
                        {...getInputProps({
                            placeholder: t('vende.form.placeholders.ubicacion'),
                            className: 'form-control',
                            disabled
                        })}
                    />
                    <div className='places-search-container'>
                        {loading && <div className='suggestion-item'>Loading...</div>}
                        {suggestions.map((suggestion, i) => {
                            const className = suggestion.active
                                ? 'suggestion-item-active'
                                : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style
                                    })}
                                    key={'place-suggestion-' + i}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    );
};

GooglePlacesAutocomplete.propTypes = {
    defaultValue: PropTypes.string,
    country: PropTypes.string,
    disabled: PropTypes.bool,
    onSelectResult: PropTypes.func
};
export default GooglePlacesAutocomplete;
