import { useTranslation } from 'react-i18next';

export default function EsTaller ({ onOpenAltaTaller }) {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div className="w-100 d-flex align-items-center justify-content-center py-4 py-md-0 seccion es-taller">
                <div className="col-12 col-md-7 es-taller__container">
                    <h4>{t('home.estaller.title')}</h4>

                    <p className="mt-1">
                        {t('home.estaller.text')}
                    </p>

                    <button
                        type="button"
                        className="btn btn-primary mt-4"
                        onClick={() => onOpenAltaTaller()}
                    >
                        {t('home.estaller.button')}
                    </button>
                </div>
            </div>
        </div>
    );
}
