import { Suspense, lazy, useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import NavbarMenu from './shared/components/Navbar';
import Home from './components/Home';
import Contacto from './components/Contacto';
import TrabajaConNosotros from './components/TrabajaConNosotros';
import VendeCoche from './components/VendeCoche';
import PoliticaPrivacidad from './components/PoliticaPrivacidad';
import PoliticaCookies from './components/PoliticaCookies';
import AvisoLegal from './components/AvisoLegal';
import CondicionesGenerales from './components/CondicionesGenerales';
import Blog from './components/Blog';
import { getDepartamentos } from './shared/services/trabaja_con_nosotros';
import { getMarcas, getModelos } from './shared/services/vende_coche';
import { sendWorkshopRegistration } from './shared/components/AltaTallerModal/services/alta_taller';
import PostDetail from './components/Blog/components/PostDetail';
import { es } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';
import FloatingWhatsApp from './shared/components/FloatingWhatsApp';
import AltaTaller from './components/AltaTaller';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const Footer = lazy(() => import('./shared/components/Footer/index'));
const AltaTallerModal = lazy(() => import('./shared/components/AltaTallerModal/index'));

const Main = () => {
    const { t } = useTranslation();
    const [isOpenAltaTaller, setIsOpenAltaTaller] = useState(false);

    useEffect(() => {
        setDefaultOptions({ locale: es });

        const buildDepartments = async () => {
            try {
                const response = await getDepartamentos();

                if (response.success) {
                    localStorage.setItem('departamentos', JSON.stringify(response.departamentos));
                } else {
                    throw new Error('Error en la llamada POST');
                }
            } catch (error) {
                console.error(error);
            }
        };

        const buildBrands = async () => {
            try {
                const response = await getMarcas();

                if (response.success) {
                    localStorage.setItem('marcas', JSON.stringify(response.marcas));
                } else {
                    throw new Error('Error en la llamada');
                }
            } catch (error) {
                console.error(error);
            }
        };

        const buildModels = async () => {
            try {
                const response = await getModelos();

                if (response.success) {
                    localStorage.setItem('modelos', JSON.stringify(response.modelos));
                } else {
                    throw new Error('Error en la llamada');
                }
            } catch (error) {
                console.error(error);
            }
        };

        buildDepartments();
        buildBrands();
        buildModels();
    }, []);

    const handleOpenAltaTaller = () => setIsOpenAltaTaller(true);
    const handleCloseAltaTaller = () => setIsOpenAltaTaller(false);
    const handleSubmitAltaTaller = async (data) => {
        const response = await sendWorkshopRegistration(data);

        if (response) {
            if (response.success) {
                toast.success(response.message);
            } else {
                toast.error(response?.data?.error || t('errors.1'));
            }
            return response.success;
        } else {
            return false;
        }
    };

    return (
        <div className="w-100 position-relative">
            <NavbarMenu
                onOpenAltaTaller={handleOpenAltaTaller}
            />

            <div className="container-wrapper w-100" style={{ marginTop: '60px' }}>
                <Routes>
                    <Route path='*' exact={true} element={<Navigate to="/" />}></Route>
                    <Route path="/" exact element={<Home onOpenAltaTaller={handleOpenAltaTaller} />}></Route>
                    <Route path="/blog" element={<Blog />}></Route>
                    <Route path="/post/:slug" element={<PostDetail />} />
                    <Route path="/contacto" element={<Contacto />}></Route>
                    <Route path="/trabaja-con-nosotros" element={<TrabajaConNosotros />}></Route>
                    <Route path="/vende-para-desguace" element={<VendeCoche />}></Route>
                    <Route path="/politica-de-privacidad" element={<PoliticaPrivacidad />}></Route>
                    <Route path="/politica-de-cookies" element={<PoliticaCookies />}></Route>
                    <Route path="/aviso-legal" element={<AvisoLegal />}></Route>
                    <Route path="/condiciones-generales" element={<CondicionesGenerales />}></Route>
                    <Route path="/alta-taller" element={<AltaTaller />}></Route>
                </Routes>
            </div>
            <Suspense fallback={<div>Loading...</div>}>
                <Footer onOpenAltaTaller={handleOpenAltaTaller} />
            </Suspense>

            <Suspense fallback={<div>Loading...</div>}>
                <AltaTallerModal
                    open={isOpenAltaTaller}
                    onClose={handleCloseAltaTaller}
                    onSubmitAlta={handleSubmitAltaTaller}
                />
            </Suspense>

            <FloatingWhatsApp
                allowEsc
                allowClickAway
                notification
                // notificationSound
            />
        </div>
    );
};

export default Main;
