import axios from 'axios';

const API = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
});

export default API;
