import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const PoliticaPrivacidad = () => {
    const { t } = useTranslation();
    return (
        <div className="w-100 h-100 d-flex flex-column px-4 px-md-0 pt-4">
            <div className="w-100 d-flex align-items-center justify-content-center">
                <div className="col-12 col-md-8 col-lg-5 content-info mt-4 mb-5">
                    <h1>{t('privacidad.title')}</h1>

                    <h4>{t('privacidad.declaracion.title')}</h4>
                    <p>
                        {t('privacidad.declaracion.text')} <a href="https://www.recomotor.com" target="_blank" rel="noreferrer">www.recomotor.com</a>.
                    </p>

                    <h4>{t('privacidad.legislacion.title')}</h4>
                    <p>
                        {t('privacidad.legislacion.text')}
                    </p>

                    <h4>{t('privacidad.titularidad.title')}</h4>
                    <p>
                        {t('privacidad.titularidad.text')}
                    </p>

                    <h4>{t('privacidad.responsable.title')}</h4>
                    <p>{t('privacidad.responsable.text.1')} <a href="https://www.recomotor.com" target="_blank" rel="noreferrer">www.recomotor.com</a> {t('privacidad.responsable.text.2')}</p>

                    <div>
                        <p><b>{t('privacidad.responsable.dominio')}</b> www.recomotor.com</p>
                        <p><b>{t('privacidad.responsable.comercial')}</b> Recomotor</p>
                        <p><b>{t('privacidad.responsable.denominacion')}</b> JG RECOMOTOR S.L.</p>
                        <p><b>NIF:</b> B04950093</p>
                        <p><b>{t('privacidad.responsable.direccion')} </b> Polígono dels Frares, Calle D, parcela número 46, 25190 Lleida</p>
                        <p><b>{t('privacidad.responsable.tlf')}</b> +34 644 175 425</p>
                        <p><b>E-mail:</b> info@recomotor.com</p>

                        <p className="mt-2">{t('privacidad.responsable.inscrita')}</p>
                    </div>

                    <h4>{t('privacidad.finalidad.title')}</h4>
                    <p>{t('privacidad.finalidad.text')}</p>

                    <div>
                        <ul>
                            <li>{t('privacidad.finalidad.ul.1')}</li>
                            <li>{t('privacidad.finalidad.ul.2')}</li>
                            <li>{t('privacidad.finalidad.ul.3')}</li>
                            <li>{t('privacidad.finalidad.ul.4')}</li>
                            <li>{t('privacidad.finalidad.ul.5')}</li>
                        </ul>
                    </div>

                    <p>
                        {t('privacidad.finalidad.text2')} <a href="https://www.recomotor.com" target="_blank" rel="noreferrer">www.recomotor.com</a>{t('privacidad.finalidad.text3')}
                    </p>

                    <h4>{t('privacidad.autorizacion.title')}</h4>
                    <p>
                        {t('privacidad.autorizacion.text.1')} <a href="https://www.recomotor.com" target="_blank" rel="noreferrer">www.recomotor.com</a>{t('privacidad.autorizacion.text.2')}
                    </p>

                    <h4>{t('privacidad.divulgacion.title')}</h4>
                    <p>
                        {t('privacidad.divulgacion.text.1')}
                    </p>

                    <p>
                        {t('privacidad.divulgacion.text.2')}
                    </p>

                    <div className="my-3">
                        <ul>
                            <li>{t('privacidad.divulgacion.text.ul.1')}</li>
                            <li>{t('privacidad.divulgacion.text.ul.2')}</li>
                            <li>{t('privacidad.divulgacion.text.ul.3')}</li>
                            <li>{t('privacidad.divulgacion.text.ul.4')}</li>
                            <li>{t('privacidad.divulgacion.text.ul.5')}</li>
                            <li>{t('privacidad.divulgacion.text.ul.6')}</li>
                        </ul>
                    </div>

                    <p>
                        {t('privacidad.divulgacion.text.3')}
                    </p>

                    <div className="my-3">
                        <ul>
                            <li>{t('privacidad.divulgacion.text.ul2.1')}</li>
                            <li>{t('privacidad.divulgacion.text.ul2.2')}</li>
                            <li>{t('privacidad.divulgacion.text.ul2.3')}</li>
                            <li>{t('privacidad.divulgacion.text.ul2.4')}</li>
                        </ul>
                    </div>

                    <p>
                        {t('privacidad.divulgacion.text.4')} <a href="https://www.privacyshield.gov/list" target="_blank" rel="noreferrer">https://www.privacyshield.gov/list</a>.
                    </p>

                    <h4>{t('privacidad.conservacion.title')}</h4>
                    <p>
                        {t('privacidad.conservacion.text.1')} <a href="https://www.recomotor.com" target="_blank" rel="noreferrer">www.recomotor.com</a> {t('privacidad.conservacion.text.2')}
                    </p>
                    <p>
                        {t('privacidad.conservacion.text.3')}
                    </p>

                    <h4>{t('privacidad.legitimacion.title')}</h4>
                    <p>
                        {t('privacidad.legitimacion.text.1')}
                    </p>
                    <p>
                        {t('privacidad.legitimacion.text.2')} <a href="https://www.recomotor.com" target="_blank" rel="noreferrer">www.recomotor.com</a> {t('privacidad.legitimacion.text.3')}
                    </p>

                    <h4>{t('privacidad.seguridad.title')}</h4>
                    <p>
                        {t('privacidad.seguridad.text.1')}
                    </p>
                    <p>
                        {t('privacidad.seguridad.text.2')}
                    </p>
                    <p>
                        {t('privacidad.seguridad.text.3')}
                    </p>
                    <p>
                        {t('privacidad.seguridad.text.4')}
                    </p>
                    <p>
                        {t('privacidad.seguridad.text.5')}
                    </p>

                    <h4>{t('privacidad.menores.title')}</h4>
                    <p>
                        {t('privacidad.menores.text.1')}
                    </p>
                    <p>
                        {t('privacidad.menores.text.2')}
                    </p>

                    <h4>{t('privacidad.cookies.title')}</h4>
                    <p>
                        {t('privacidad.cookies.text.1')} <a href="https://www.recomotor.com" target="_blank" rel="noreferrer">www.recomotor.com</a> {t('privacidad.cookies.text.2')}
                    </p>
                    <p>
                        {t('privacidad.cookies.text.3')} <Link to={'/cookies'} title="Política de Cookies">Política de Cookies</Link>.
                    </p>

                    <h4>{t('privacidad.datos.title')}</h4>
                    <p>
                        {t('privacidad.datos.text.1')}
                    </p>

                    <div>
                        <ul>
                            <li>{t('privacidad.datos.text.ul.1')}</li>
                            <li>{t('privacidad.datos.text.ul.2')}</li>
                            <li>{t('privacidad.datos.text.ul.3')}</li>
                            <li>{t('privacidad.datos.text.ul.4')}</li>
                            <li>{t('privacidad.datos.text.ul.5')}</li>
                            <li>{t('privacidad.datos.text.ul.6')}</li>
                        </ul>
                    </div>

                    <p>{t('privacidad.datos.text.2')}</p>
                    <div>
                        <ul>
                            <li>{t('privacidad.datos.text.ul2.1')}</li>
                            <li>{t('privacidad.datos.text.ul2.2')}</li>
                            <li>{t('privacidad.datos.text.ul2.3')}</li>
                            <li>{t('privacidad.datos.text.ul2.4')}</li>
                            <li>{t('privacidad.datos.text.ul2.5')}</li>
                            <li>{t('privacidad.datos.text.ul2.6')}</li>
                            <li>{t('privacidad.datos.text.ul2.7')}</li>
                        </ul>
                    </div>

                    <p>
                        {t('privacidad.datos.text.3')}
                    </p>

                    <h4>{t('privacidad.derechos.title')}</h4>
                    <p>
                        {t('privacidad.derechos.text.1')} <Link to={'/condiciones-generales'} title="Condiciones Generales de Venta">{t('privacidad.derechos.text.1-1')}</Link>.
                    </p>
                    <p>
                        {t('privacidad.derechos.text.2')} <a href="https://www.recomotor.com" target="_blank" rel="noreferrer">www.recomotor.com</a> {t('privacidad.derechos.text.3')}
                    </p>
                    <p>
                        {t('privacidad.derechos.text.4')}
                    </p>
                    <p>
                        {t('privacidad.derechos.text.5')}
                    </p>

                    <div>
                        <ul>
                            <li>{t('privacidad.derechos.text.ul.1')}</li>
                            <li>{t('privacidad.derechos.text.ul.2')}</li>
                            <li>{t('privacidad.derechos.text.ul.3')}</li>
                            <li>{t('privacidad.derechos.text.ul.4')}</li>
                            <li>{t('privacidad.derechos.text.ul.5')}</li>
                            <li>{t('privacidad.derechos.text.ul.6')}</li>
                        </ul>
                    </div>

                    <p>
                        {t('privacidad.derechos.text.6')}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PoliticaPrivacidad;
