import { useTranslation } from 'react-i18next';

const CondicionesGenerales = () => {
    const { t } = useTranslation();
    return (
        <div className="w-100 h-100 d-flex flex-column px-4 px-md-0 pt-4">
            <div className="w-100 d-flex align-items-center justify-content-center">
                <div className="col-12 col-md-8 col-lg-6 content-info mt-4 mb-5">
                    <h1>{t('condiciones-generales.title')}</h1>

                    <h2>{t('condiciones-generales.garantias.title')}</h2>
                    <p>
                        {t('condiciones-generales.garantias.text.1')}
                    </p>

                    <p>
                        {t('condiciones-generales.garantias.text.2')}
                        <b> {t('condiciones-generales.garantias.text.3')} </b>
                        {t('condiciones-generales.garantias.text.4')}
                        <b> {t('condiciones-generales.garantias.text.5')} </b>
                        {t('condiciones-generales.garantias.text.6')} <br/><br />
                        <b>{t('condiciones-generales.garantias.text.7')} </b>
                        {t('condiciones-generales.garantias.text.8')}
                        <b> {t('condiciones-generales.garantias.text.9')}</b> <br/><br />
                        <b>{t('condiciones-generales.garantias.text.10')} </b>
                        {t('condiciones-generales.garantias.text.11')} <b>
                            {t('condiciones-generales.garantias.text.12')}</b> <br/><br />
                        <b>{t('condiciones-generales.garantias.text.13')}</b>
                        {t('condiciones-generales.garantias.text.14')}
                        <b> {t('condiciones-generales.garantias.text.15')}</b>
                    </p>

                    <h3>{t('condiciones-generales.devolucion.title')}</h3>
                    <p>{t('condiciones-generales.devolucion.text.1')}</p>

                    <p style={{ marginTop: '10px' }}>
                        {t('condiciones-generales.devolucion.text.2')} <a href="mailto:info@recomotor.com">info@recomotor.com</a>
                    </p>

                    <p style={{ marginTop: '10px' }}>
                        {t('condiciones-generales.devolucion.text.3')}
                    </p>

                    <h3>{t('condiciones-generales.plazos-entrega.title')}</h3>
                    <p>{t('condiciones-generales.plazos-entrega.text.1')}
                    </p>

                    <h3>{t('condiciones-generales.daños-ocasionados.title')}</h3>
                    <p>{t('condiciones-generales.daños-ocasionados.text.1')}</p>

                    <h3>{t('condiciones-generales.reserva.title')}</h3>
                    <p>
                        {t('condiciones-generales.reserva.text.1')}
                        <b> {t('condiciones-generales.reserva.text.2')} </b>
                        {t('condiciones-generales.reserva.text.3')}
                        <b> {t('condiciones-generales.reserva.text.4')} </b>
                        {t('condiciones-generales.reserva.text.5')}
                    </p>

                    <p className='mt-5'>
                        <b>{t('condiciones-generales.no-conforme')}</b>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CondicionesGenerales;
