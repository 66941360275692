import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormInput from './FormInput';
import { useParams } from 'react-router-dom';
import Spinner from '../../../Main/shared/components/Spinner';
import { submitSendBudget } from '../../services/budgets';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';

const FormClient = ({ setWasSentEmail }) => {
    const { t } = useTranslation();
    const recaptcha = useRef();
    const { checkoutId } = useParams();
    const [clientData, setClientData] = useState({
        name: '',
        email: ''
    });
    const [error, setError] = useState({
        name: '',
        email: ''
    });

    const [isLoading, setIsLoading] = useState();
    const handleClientData = (e) => {
        if (error[e.target.name]) {
            setError({ ...error, [e.target.name]: '' });
        }
        setClientData({
            ...clientData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!clientData.name) {
            return setError({ ...error, name: t('budget.form.errors.name.required') });
        };
        if (!clientData.email) {
            return setError({ ...error, email: t('budget.form.errors.email.required') });
        };
        if (!/\S+@\S+\.\S+/.test(clientData.email)) {
            return setError({ ...error, email: t('budget.form.errors.email.formato') });
        };
        const captchaValue = recaptcha.current.getValue();
        if (!captchaValue) return toast.error(t('errors.captcha'));
        setIsLoading(true);

        // REQUEST
        const res = await submitSendBudget({ ...clientData, checkoutId, captchaValue });
        if (!res?.success) {
            setIsLoading(false);
            return toast.error(t('budget.no-sent'));
        }
        setWasSentEmail(true);
        return setIsLoading(false);
    };

    return (
        <div className="send-budget__form-client">
            <div className='send-budget__form-client--container'>
                <h2>{t('budget.form.title')}</h2>
                <form>
                    <FormInput
                        id="budget-name"
                        text={t('budget.form.name')}
                        name="name"
                        type="text"
                        onChange={handleClientData}
                        value={clientData.name}
                        errorMessage={error.name}
                    />
                    <FormInput
                        id="budget-email"
                        text={t('budget.form.email')}
                        name="email"
                        type="email"
                        onChange={handleClientData}
                        value={clientData.email}
                        inputProps={{ pattern: /\S+@\S+\.\S+/ }}
                        errorMessage={error.email}
                    />
                    <div className='w-100 d-flex align-items-center justify-content-center captcha'>
                        <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                    </div>
                    <button onClick={handleSubmit}>{isLoading ? <Spinner /> : t('budget.form.send')}</button>
                </form>
            </div>
        </div>
    );
};

export default FormClient;
