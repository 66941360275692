import { useTranslation } from 'react-i18next';

export default function Hero () {
    const { t } = useTranslation();
    return (
        <div className="hero vende d-flex flex-column align-items-center justify-content-center position-relative">
            <h1>{t('vende.hero.title.1')} <br /> {t('vende.hero.title.2')}</h1>
        </div>
    );
}
