import { useEffect } from 'react';
import './footer.scss';
import logo from '../../../../../images/recomotor_logo_footer.png';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Footer () {
    useEffect(() => {
    }, []);

    return (
        <footer className='w-100 d-flex flex-column footer mt-5'>
            <div className='w-100 container d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between info mb-md-5'>
                <img src={logo} alt='Recomotor' className='footer-logo' />

                <div className='d-flex flex-column'>
                    <small>¿Tienes alguna duda? Ponte en contacto con nosotros</small>

                    <div className='d-flex flex-wrap flex-md-nowrap align-items-center justify-content-end'>
                        <small className='d-flex align-items-center'>
                            <FontAwesomeIcon icon={faEnvelope} size="1x" color="#FFF" />
                            <a href='mailto:info@recomotor.com' className='ms-2'>info@recomotor.com</a>
                        </small>

                        <small className='d-flex align-items-center ms-3'>
                            <FontAwesomeIcon icon={faPhone} size="1x" color="#FFF" />
                            <a href='telf:+34644175425' className='ms-2'>644 17 54 25</a>
                        </small>
                    </div>
                </div>
            </div>

            <div className='w-100 d-flex flex-column align-items-center links mt-5'>
                <small>© Copyright 2024 Recomotor</small>

                <div className='w-100 d-flex flex-column flex-md-row align-items-center justify-content-center mt-2'>
                    <div className='px-1 d-flex align-items-center justify-content-center'>
                        <a className='a-small' title='Aviso Legal' href='https://recomotor.com/aviso-legal'>Aviso Legal</a>
                    </div>

                    <div className='px-1 d-flex align-items-center justify-content-center'>
                        <a className='a-small' title='Política de privacidad' href='https://recomotor.com/politica-de-privacidad'>Política de privacidad</a>
                    </div>

                    <div className='px-1 d-flex align-items-center justify-content-center'>
                        <a className='a-small' title='Política de cookies' href='https://recomotor.com/politica-de-cookies'>Política de cookies</a>
                    </div>

                    <div className='px-1 d-flex align-items-center justify-content-center'>
                        <a className='a-small' title='Condiciones generales' href='https://recomotor.com/condiciones-generales'>Condiciones generales</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}
