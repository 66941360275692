import './spinner.scss';
import PropTypes from 'prop-types';

const Spinner = ({ className = '' }) => {
    return (
        <span className={`simple-spinner ${className}`}>
            <span></span>
        </span>
    );
};

Spinner.propTypes = {
    className: PropTypes.string
};

export default Spinner;
