import { useEffect, useState } from 'react';
import './blog.scss';
import Post from './components/Post';
import { getPosts } from '../../shared/services/blog';
import Pagination from '../../shared/components/Pagination';
import recambios from '../../../../images/eco.png';

export default function Blog () {
    const [isLoading, setIsLoading] = useState(true);
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 9;

    useEffect(() => {
        const buildPosts = async () => {
            try {
                setIsLoading(true);
                const response = await getPosts();
                if (response.success) {
                    setPosts(response.posts);
                    setIsLoading(false);
                } else {
                    throw new Error('Error en la llamada');
                }
            } catch (error) {
                console.error(error);
            }
        };
        buildPosts();
    }, []);

    // Calcula el índice inicial y final de los posts para la página actual
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

    // Obtiene el número total de páginas
    const totalPages = Math.ceil(posts.length / postsPerPage);

    // Cambia de página
    const paginate = pageNumber => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    return (
        <div className="seccion w-100 contacto mb-5 blog">
            <div className="container d-flex flex-column align-items-center">
                <h1>Blog</h1>
                { isLoading
                    ? (
                        <div className="w-100 h-100 d-flex align-items-center justify-content-center my-5">
                            <div className="my-5">
                                <img src={recambios} width={25} height={25} alt="Loading" className="loading" />
                                <span className="ms-3">Cargando..</span>
                            </div>
                        </div>
                    )
                    : (
                        <>
                            <div className="w-100 d-flex flex-wrap align-items-stretch post-list gutter-20 gutter-vertical-20">
                                {currentPosts.map(post => (
                                    <Post
                                        key={post.id}
                                        title={post.title?.rendered}
                                        resume={post.excerpt?.rendered}
                                        img={post._embedded['wp:featuredmedia'] ? post._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url : '/images/placeholder-image.png'}
                                        slug={post.slug}
                                    />
                                ))}
                            </div>

                            <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={posts.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        </>
                    )}
            </div>
        </div>
    );
}
