import recambistas from '../../../../../../images/users.png';
import recambios from '../../../../../../images/eco.png';
import coches from '../../../../../../images/coche.png';
import dinero from '../../../../../../images/monedas.png';
import { useTranslation } from 'react-i18next';

export default function Hero2 () {
    const { t } = useTranslation();
    return (
        <div className="seccion seccion-hero2">
            <div className="hero2 d-flex align-items-center justify-content-center">
                <div className="container h-100 position-relative">
                    <div className="w-100 h-100 d-flex flex-column justify-content-center">
                        <div className="hero2-img" />
                        <div className="hero2-text">
                            <h5>{t('home.hero2.subtitle')}</h5>
                            <h2 className="mt-2 mb-3">{t('home.hero2.title.1')} <b>{t('home.hero2.title.2')}</b></h2>
                            <p className="text-start">
                                {t('home.hero2.text')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container feature-content">
                <div className="d-flex flex-wrap">
                    <div className="col-12 col-md-6 col-lg-3 d-flex flex-column align-items-center justify-content-center">
                        <div className="feature-item feature-item-hero2">
                            <img src={recambistas} alt="Recambistas" />
                            <h4 className="m-2 feature-item-hero2__recambistas">{t('home.hero2.feature-item1.title')}</h4>
                            <p className="px-5 px-md-0 ">{t('home.hero2.feature-item1.text')}</p>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-3 d-flex flex-column align-items-center justify-content-center">
                        <div className="feature-item feature-item-hero2">
                            <img src={recambios} alt="Recambios" />
                            <h4 className="m-2">{t('home.hero2.feature-item2.title')}</h4>
                            <p className="px-5 px-md-0 ">{t('home.hero2.feature-item2.text')}</p>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-3 d-flex flex-column align-items-center justify-content-center">
                        <div className="feature-item feature-item-hero2">
                            <img src={coches} alt="Automoción" className='feature-item__car-img' />
                            <h4 className="m-2">{t('home.hero2.feature-item3.title')}</h4>
                            <p className="px-5 px-md-0 ">{t('home.hero2.feature-item3.text')}</p>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-3 d-flex flex-column align-items-center justify-content-center">
                        <div className="feature-item feature-item-hero2">
                            <img src={dinero} alt="Mejor Precio" />
                            <h4 className="m-2 feature-item-hero2__precio">{t('home.hero2.feature-item4.title')}</h4>
                            <p className="px-5 px-md-0 ">{t('home.hero2.feature-item4.text')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
