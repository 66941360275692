import { useEffect, useRef, useState } from 'react';
import Footer from '../shared/components/Footer';
import Header from '../shared/components/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { setErrorMessage } from '../../shared/helpers/functionalities';
import { toast } from 'react-toastify';
import { getSaleInfo, getSaleInfoVO } from '../shared/services/proveedores';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { savePartProblem, saveVoPartProblem } from '../shared/services/integraciones';

export default function PartProblem ({ comesFrom }) {
    const { t } = useTranslation();
    const recaptcha = useRef();
    const { ventaId, proveedorId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [venta, setVenta] = useState(null);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            venta_id: ventaId,
            proveedor_id: proveedorId,
            pieza_id: '',
            problema: '',
            observaciones_desguace: ''
        }
    });

    useEffect(() => {
        const getInfo = async (comesFrom) => {
            let response;

            if (comesFrom) {
                response = await getSaleInfoVO(ventaId, proveedorId).catch(function (error) {
                    toast.error(setErrorMessage(error));
                });
            } else {
                response = await getSaleInfo(ventaId, proveedorId).catch(function (error) {
                    toast.error(setErrorMessage(error));
                });
            }

            if (response && response.success) {
                setLoading(false);
                setVenta(response.data);
            }
        };

        if (proveedorId && ventaId) {
            getInfo(comesFrom);
        } else toast.error('No hay venta!');
    }, []);

    const handleSubmitPartProblem = (data, e) => {
        e.preventDefault();

        const captchaValue = recaptcha.current.getValue();
        if (!captchaValue) return toast.error(t('errors.captcha'));

        handlePartProblem({ ...data, captchaValue });
    };

    const handlePartProblem = async (data) => {
        let response;

        if (comesFrom) {
            response = await saveVoPartProblem(data).catch(function (error) {
                toast.error(setErrorMessage(error));
            });
        } else {
            response = await savePartProblem(data).catch(function (error) {
                toast.error(setErrorMessage(error));
            });
        }

        if (response && response.success) {
            toast.success(response.message);
            navigate('/gracias', { state: { from: 'providers' } });
        }
    };

    return (
        <div className='w-100 h-100 d-flex flex-column justify-content-between'>
            <Header />

            { (loading)
                ? (
                    <div className="w-100 h-100 d-flex align-items-center justify-content-center my-5">
                        <div className="w-100 h-100 d-flex align-items-center justify-content-center my-5">
                            <span className="my-5">{ t('common.loading') }</span>
                        </div>
                    </div>
                )
                : (
                    <div className='w-100 container d-flex align-items-center justify-content-center mt-5'>
                        <form className="form col-12 col-md-8 col-xl-6" onSubmit={handleSubmit(handleSubmitPartProblem)}>
                            <input
                                type="hidden"
                                {...register('venta_id')}
                                defaultValue={ventaId} />
                            <input
                                type="hidden"
                                {...register('proveedor_id')}
                                defaultValue={proveedorId} />

                            <div className='w-100 mb-4'>
                                <p className='text-start'>
                                    { t('common.hi') } <span className="fw-bold">{venta?.proveedor.nombre}</span>! <br />
                                    { t('crm-views.integrations.note3') }
                                </p>
                            </div>

                            { (venta?.piezas?.length > 1) &&
                                <div className="w-100 alert alert-info mt-2">
                                    <div className="d-flex align-items-center">
                                        <FontAwesomeIcon icon={faInfoCircle} size="1x" className="action" color="#62B1F6" />
                                        <small className="ms-2 mb-0">
                                            <b>{ t('common.note') }</b>:
                                            { t('crm-views.integrations.note4') }
                                        </small>
                                    </div>
                                </div>
                            }

                            <div className='w-100'>
                                <div className='w-100 table-responsive'>
                                    <table className='table'>
                                        <tbody>
                                            { venta.piezas?.map(pieza => {
                                                return (
                                                    <tr key={'pieza-' + pieza.id}>
                                                        <td width="6%">
                                                            <input type="radio" {...register('pieza_id', { required: true })} value={pieza.id} />
                                                        </td>
                                                        <td>
                                                            <small className='fw-bold'>#{pieza.id_referencia_plataforma}</small>
                                                        </td>
                                                        <td>
                                                            <div className='d-flex flex-column'>
                                                                <small>REF: {pieza.referencia_pieza}</small>
                                                                <small>{pieza.nombre}</small>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>

                                    { (errors.pieza_id) &&
                                        <div className="invalid-feedback text-center mt-2 p-3 d-block">
                                            <div className="w-100 d-flex align-items-center justify-content-center text-danger">
                                                <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action me-1" color="#dc3545" />
                                                <span className="mx-2">{ t('crm-views.integrations.errors.part') }</span>
                                                <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action me-1" color="#dc3545" />
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="problema">{ t('crm-views.integrations.problem') }</label>
                                    <select
                                        {...register('problema', { required: true })}
                                        className="form-control"
                                        defaultValue={''}
                                    >
                                        <option value={''}>{ t('crm-views.integrations.problem_types.select') }</option>
                                        <option value="1">{ t('crm-views.integrations.problem_types.stock') }</option>
                                        <option value="2">{ t('crm-views.integrations.problem_types.breaks') }</option>
                                    </select>

                                    { (errors.problema) &&
                                        <div className="invalid-feedback text-center mt-2 p-3 d-block">
                                            <div className="w-100 d-flex align-items-center justify-content-center text-danger">
                                                <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action me-1" color="#dc3545" />
                                                <span className="mx-2">{ t('crm-views.integrations.errors.problem') }</span>
                                                <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action me-1" color="#dc3545" />
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="form-group">
                                    <label htmlFor="obervaciones">{ t('crm-views.integrations.observations') } { t('crm-views.integrations.optional') }</label>
                                    <textarea
                                        className="form-control"
                                        {...register('observaciones_desguace')}
                                        defaultValue={''}
                                    ></textarea>
                                </div>
                            </div>

                            <div className='w-100 d-flex align-items-center justify-content-center'>
                                <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                            </div>

                            <div className="w-100 d-flex align-items-center justify-content-center mt-5">
                                <button type="submit" className="btn btn-primary order-0 order-md-1">{ t('common.send') }</button>
                            </div>
                        </form>
                    </div>
                )}

            <Footer />
        </div>
    );
}
