import API from '../../../../services/api';
import { headers } from '../../../shared/helpers/functionalities';

export const sendOfflineResponse = async (data) => {
    return await API.post('/offline/response', data)
        .then(res => res.data);
};

export const sendOfflineResponseData = async (data) => {
    return await API.post('/offline/response-data', data, data instanceof FormData ? headers : '')
        .then(res => res.data)
        .catch(error => error.message);
};
