import step01 from '../../../../../../images/pasos/01.png';
import step02 from '../../../../../../images/pasos/02.png';
import step03 from '../../../../../../images/pasos/03.png';
import step04 from '../../../../../../images/pasos/04.png';
import step01Icon from '../../../../../../images/pasos/paso1.png';
import step02Icon from '../../../../../../images/pasos/paso2.png';
import step03Icon from '../../../../../../images/pasos/paso3.png';
import step04Icon from '../../../../../../images/pasos/paso4.png';
import { useTranslation } from 'react-i18next';

export default function Procedimiento () {
    const { t } = useTranslation();
    return (
        <div className="seccion">
            <div className="w-100 d-flex align-items-center justify-content-center py-4 py-md-0 procedimiento seccion">
                <div className="container">
                    <h2 className="text-center">{t('home.procedimiento.title')}</h2>
                    <h5 className="text-center mt-2">{t('home.procedimiento.subtitle')}</h5>

                    <div className="procedimiento-steps d-flex flex-column flex-md-row">
                        <div className="procedimiento-step">
                            <div className="d-flex flex-column align-items-center">
                                <img className="procedimiento-step__number" id='step01' src={step01} alt="Paso 1" />
                                <span className="step-step">
                                    <img src={step01Icon} className='step-step__img1' alt="Solicitud" />
                                </span>
                                <h4 className="my-3">{t('home.procedimiento.step1.title')}</h4>
                            </div>

                            <p>
                                <b>{t('home.procedimiento.step1.text.1')}</b> {t('home.procedimiento.step1.text.2')}
                            </p>
                        </div>

                        <div className="procedimiento-step">
                            <div className="d-flex flex-column align-items-center">
                                <img className="procedimiento-step__number" id='step02' src={step02} alt="Paso 2" />
                                <span className="step-step">
                                    <img src={step02Icon} className='step-step__img2' alt="Presupuesto" />
                                </span>
                                <h4 className="my-3">{t('home.procedimiento.step2.title')}</h4>
                            </div>

                            <p>
                                {t('home.procedimiento.step2.text.1')} <b>{t('home.procedimiento.step2.text.2')}</b> {t('home.procedimiento.step2.text.3')}
                            </p>
                        </div>

                        <div className="procedimiento-step">
                            <div className="d-flex flex-column align-items-center">
                                <img className="procedimiento-step__number" id='step03' src={step03} alt="Paso 3" />
                                <span className="step-step">
                                    <img src={step03Icon} className="small step-step__img3" alt="Pago" />
                                </span>
                                <h4 className="my-3">{t('home.procedimiento.step3.title')}</h4>
                            </div>

                            <p>
                                <b>{t('home.procedimiento.step3.text.1')}</b> {t('home.procedimiento.step3.text.2')}
                            </p>
                        </div>

                        <div className="procedimiento-step">
                            <div className="d-flex flex-column align-items-center">
                                <img className="procedimiento-step__number" id='step04' src={step04} alt="Paso 4" />
                                <span className="step-step">
                                    <img src={step04Icon} className='step-step__img4' alt="Envío" />
                                </span>
                                <h4 className="my-3">{t('home.procedimiento.step4.title')}</h4>
                            </div>

                            <p>
                                {t('home.procedimiento.step4.text.1')} <b>{t('home.procedimiento.step4.text.2')}</b>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
