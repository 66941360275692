import './trabaja_con_nosotros.scss';
import TrabajaConNosotrosForm from './components/TrabajaConNosotrosForm';
import img from '../../../../images/trabaja_con_nosotros.png';
import { toast } from 'react-toastify';
import { getOpenPositions, sendWorkWithUs } from '../../shared/services/trabaja_con_nosotros';
import { useTranslation } from 'react-i18next';
import PosicionesActivas from './components/PosicionesActivas';
import { useEffect, useState } from 'react';

export default function TrabajaConNosotros () {
    const { t } = useTranslation();
    const [positionsToShow, setPositionsToShow] = useState(null);

    const handleSendCV = async (data) => {
        const formData = new FormData();
        formData.append('nombre', data.nombre);
        formData.append('email', data.email);
        formData.append('telefono', data.telefono);
        formData.append('departmento_id', data.departmento_id);
        formData.append('mensaje', data.mensaje);
        formData.append('cv', data.cv[0]);
        formData.append('captchaValue', data.captchaValue);

        const response = await sendWorkWithUs(formData);

        if (response) {
            if (response.success) {
                toast.success(response.message);
            } else {
                toast.error(response?.data?.error || t('errors.1'));
            }
            return response.success;
        } else {
            return false;
        }
    };

    const getPositionsRequest = async () => {
        const res = await getOpenPositions();
        setPositionsToShow(res);
    };

    useEffect(() => {
        getPositionsRequest();
    }, []);

    return (
        <div className="seccion w-100 trabaja-con-nosotros">
            <div className="d-flex flex-wrap flex-md-nowrap w-100">
                <div className="col-12 col-md-6 px-3 position-relative d-flex align-items-center justify-content-center trabaja-con-nosotros__container-img">
                    <div className="w-100 d-flex section-info flex-column align-items-center justify-content-center rounded"
                        style={{ backgroundImage: `url(${img})` }}>
                        <div className="section-info-text-content">
                            <h3 className="w-100 fw-bold section-info-text">{t('trabaja.title')}</h3>
                            <h5 className="w-100 mt-4 section-info-text section-info-text__black">
                                {t('trabaja.text')}
                            </h5>
                        </div>
                        <div className='section-info-video-content'>
                            <iframe src="https://www.youtube.com/embed/fzp2oRRc8OQ?si=7N1LNi-KAdtL8v0a" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 p-3 d-flex flex-column align-items-center justify-content-center">
                    <PosicionesActivas
                        positionsToShow={positionsToShow}
                    />
                    <TrabajaConNosotrosForm
                        onSubmitCV={handleSendCV}
                    />
                </div>
            </div>
        </div>
    );
}
