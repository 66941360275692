import { useEffect, useRef, useState } from 'react';
import Footer from '../shared/components/Footer';
import Header from '../shared/components/Header';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { setErrorMessage } from '../../shared/helpers/functionalities';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { sendReconstruidoResponse, sendReconstruidoResponseData } from '../shared/services/reconstruidos';

export default function ReconstruidosRequestResponse () {
    const { t } = useTranslation();
    const recaptcha = useRef();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [answer, setAnswer] = useState(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            solicitud_id: '',
            respuesta_id: '',
            reconstruido_id: '',
            precio: 0,
            estado: '',
            contacto: '',
            img: null
        }
    });

    useEffect(() => {
        const reqId = searchParams.get('id');
        const item = searchParams.get('item');
        const available = searchParams.get('disponible') ? (searchParams.get('disponible') === 'true') : null;

        const sendResponse = async (reqId, item, available) => {
            const response = await sendReconstruidoResponse({
                id: reqId,
                item,
                disponible: available
            }).catch(function (error) {
                toast.error(setErrorMessage(error));
            });

            if (response && response.success) {
                // si recibimos true es que ya habian contestado previamente lo mismo
                if (response.data === true) {
                    navigate('/gracias', { state: { from: 'reconstruido' } });
                } else {
                    if (available) {
                        setValue('solicitud_id', response.data.solicitud_id);
                        setValue('respuesta_id', response.data.respuesta_id);
                        setValue('reconstruido_id', response.data.reconstruido_id);
                        setAnswer(response.data);
                        setLoading(false);
                    } else {
                        navigate('/gracias', { state: { from: 'reconstruidos' } });
                    }
                }
            }
        };

        if (reqId && item && (available !== null)) {
            sendResponse(reqId, item, available);
        } else toast.error('La petición esta incompleta!');
    }, []);

    const handleSubmitResponse = (data, e) => {
        e.preventDefault();

        const captchaValue = recaptcha.current.getValue();
        if (!captchaValue) return toast.error(t('errors.captcha'));

        handleSubmitResponseData({ ...data, captchaValue });
    };

    const handleSubmitResponseData = async (data) => {
        let sendData = data;

        if (data.img) {
            const formData = new FormData();

            Object.entries(data).forEach((value) => {
                if ((value[0] === 'img') && value[1]?.length > 0) {
                    formData.append(value[0], value[1][0]);
                } else { formData.append(value[0], value[1]); }
            });

            sendData = formData;
        }

        const response = await sendReconstruidoResponseData(sendData).catch(function (error) {
            toast.error(setErrorMessage(error));
        });

        if (response && response.success) {
            toast.success(response.message);
            navigate('/gracias', { state: { from: 'reconstruidos' } });
        }
    };

    return (
        <div className='w-100 h-100 d-flex flex-column justify-content-between'>
            <Header />

            { (loading)
                ? (
                    <div className='w-100 h-100 d-flex align-items-center justify-content-center my-5'>
                        <div className='w-100 h-100 d-flex align-items-center justify-content-center my-5'>
                            <span className='my-5'>{ t('common.loading') }</span>
                        </div>
                    </div>
                )
                : (
                    <div className='w-100 container d-flex align-items-center justify-content-center mt-5'>
                        <form className='form col-12 col-md-8 col-xl-6' onSubmit={handleSubmit(handleSubmitResponse)}>
                            <input
                                type='hidden'
                                {...register('solicitud_id')}
                                defaultValue={answer.solicitud_id} />
                            <input
                                type='hidden'
                                {...register('respuesta_id')}
                                defaultValue={answer.respuesta_id} />
                            <input
                                type='hidden'
                                {...register('reconstruido_id')}
                                defaultValue={answer.reconstruido_id} />

                            <div className='w-100 mb-4'>
                                <p className='text-start'>
                                    { t('common.hi') } <span className='fw-bold'>{answer?.reconstruido}</span>! <br />
                                    { t('crm-views.integrations.thanks') }
                                </p>
                            </div>

                            <div className='w-100 alert alert-info mt-2'>
                                <div className='d-flex align-items-center'>
                                    <FontAwesomeIcon icon={faInfoCircle} size='1x' className='action' color='#62B1F6' />
                                    <small className='ms-2 mb-0'>
                                        <b>{ t('common.note') }</b>:
                                        { t('crm-views.integrations.note2') }
                                    </small>
                                </div>
                            </div>

                            <div className='w-100'>
                                <div className='form-group flex-grow-1'>
                                    <label htmlFor='precio_compra'>{ t('crm-views.integrations.price') }</label>
                                    <input
                                        type='number'
                                        step='0.01'
                                        className='form-control'
                                        min={0}
                                        {...register('precio', { required: true })}
                                        defaultValue={0} />

                                    { (errors.precio) &&
                                        <div className='invalid-feedback text-center mt-2 p-3 d-block'>
                                            <div className='w-100 d-flex align-items-center justify-content-center text-danger'>
                                                <FontAwesomeIcon icon={faExclamationTriangle} size='1x' className='action me-1' color='#dc3545' />
                                                <span className='mx-2'>{ t('crm-views.integrations.errors.price_min') }</span>
                                                <FontAwesomeIcon icon={faExclamationTriangle} size='1x' className='action me-1' color='#dc3545' />
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='estado'>{ t('crm-views.integrations.status') }</label>
                                    <div className='w-100 d-flex align-items-center'>
                                        <div className='d-flex align-items-center me-3'>
                                            <input
                                                type='radio'
                                                {...register('estado', { required: true })}
                                                value='montada'
                                            />
                                            <label className='ms-2 mb-0'>{ t('crm-views.integrations.status_mounted') }</label>
                                        </div>

                                        <div className='d-flex align-items-center'>
                                            <input
                                                type='radio'
                                                {...register('estado', { required: true })}
                                                value='almacenada'
                                            />
                                            <label className='ms-2 mb-0'>{ t('crm-views.integrations.status_unmounted') }</label>
                                        </div>
                                    </div>

                                    { (errors.estado) &&
                                        <div className='invalid-feedback text-center mt-2 p-3 d-block'>
                                            <div className='w-100 d-flex align-items-center justify-content-center text-danger'>
                                                <FontAwesomeIcon icon={faExclamationTriangle} size='1x' className='action me-1' color='#dc3545' />
                                                <span className='mx-2'>{ t('crm-views.errors.status') }</span>
                                                <FontAwesomeIcon icon={faExclamationTriangle} size='1x' className='action me-1' color='#dc3545' />
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='contacto'>{ t('crm-views.integrations.contact_person') }</label>
                                    <input
                                        type='text'
                                        {...register('contacto', { required: true })}
                                        className='form-control'
                                    />

                                    { (errors.estado) &&
                                        <div className='invalid-feedback text-center mt-2 p-3 d-block'>
                                            <div className='w-100 d-flex align-items-center justify-content-center text-danger'>
                                                <FontAwesomeIcon icon={faExclamationTriangle} size='1x' className='action me-1' color='#dc3545' />
                                                <span className='mx-2'>{ t('crm-views.integrations.errors.contact_person') }</span>
                                                <FontAwesomeIcon icon={faExclamationTriangle} size='1x' className='action me-1' color='#dc3545' />
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='img'>{ t('crm-views.integrations.part_img') } { t('crm-views.integrations.optional') }</label>
                                    <input type='file' {...register('img')} className='form-control' />
                                </div>

                                <div className='form-group'>
                                    <label htmlFor='observaciones'>{ t('crm-views.integrations.observations') } { t('crm-views.integrations.optional') }</label>
                                    <textarea
                                        className='form-control'
                                        {...register('observaciones')}
                                        defaultValue={''}
                                    ></textarea>
                                </div>
                            </div>

                            <div className='w-100 d-flex align-items-center justify-content-center'>
                                <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                            </div>

                            <div className='w-100 d-flex align-items-center justify-content-center mt-5'>
                                <button type='submit' className='btn btn-primary order-0 order-md-1'>{ t('common.send') }</button>
                            </div>
                        </form>
                    </div>
                )}

            <Footer />
        </div>
    );
}
