import API from '../../../../services/api';

export const submitSurveyIncidents = async (data) => {
    return await API.post('/surveys/incidents', data)
        .then(res => res.data)
        .catch(error => error.message);
};

export const submitSurveyIncidentsVo = async (data) => {
    return await API.post('/surveys/incidentsvo', data)
        .then(res => res.data)
        .catch(error => error.message);
};
