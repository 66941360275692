import './contacto.scss';
import phone from '../../../../images/phone.png';
import mail from '../../../../images/mail.png';
import ContactoForm from './components/ContactoForm';
import { sendContact } from '../../shared/services/contacto';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function Contacto () {
    const { t } = useTranslation();

    const handleSendContacto = async (data) => {
        const response = await sendContact(data);
        if (response) {
            if (response.success) {
                toast.success(response.message);
            } else {
                toast.error(response?.data?.error || t('errors.1'));
            }
            return response.success;
        } else {
            return false;
        }
    };

    return (
        <div className="seccion w-100 contacto mb-5">
            <div className="container d-flex flex-column align-items-center">
                <div className="col-12 col-md-8 col-lg-6 col-xl-5 d-flex flex-column align-items-center">
                    <h1 className="mb-3">{t('contacto.title')}</h1>
                    <p>{t('contacto.subtitle.1')} <br /> {t('contacto.subtitle.2')}</p>

                    <div className="w-100 d-flex align-items-center justify-content-between mt-5">
                        <div className="d-flex align-items-center contacto__contact">
                            <div className="icon">
                                <img src={mail} alt="Email" />
                            </div>

                            <a href="mailto:info@recomotor.com" target="_blank" rel="noreferrer">info@recomotor.com</a>
                        </div>

                        <div className="d-flex align-items-center contacto__contact">
                            <div className="icon">
                                <img src={phone} alt="Teléfono" />
                            </div>

                            <a href="tel:+34973939392" target="_blank" rel="noreferrer">+34 973 93 93 92</a>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                    <ContactoForm
                        onSubmitContacto={handleSendContacto}
                    />
                </div>
            </div>
        </div>
    );
}
