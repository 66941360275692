import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function Step2 ({
    piezas,
    register,
    errors,
    isShow
}) {
    const { t } = useTranslation();

    useEffect(() => {
    }, [errors]);

    return (
        <div className={'w-100 ' + (isShow ? '' : 'd-none')}>
            <div className="w-100 table-responsive mb-3">
                <table className="table">
                    <tbody>
                        { piezas?.map(pieza => {
                            return (
                                <tr key={'pieza-' + pieza.id}>
                                    <td>
                                        <input type="checkbox" {...register('piezas[]')} value={pieza.id} defaultChecked={piezas.length === 1 ? 'checked' : ''} />
                                    </td>

                                    <td>
                                        <span className='text-uppercase'>{pieza.nombre}</span>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            { (errors) &&
                <div className="invalid-feedback text-center mt-2 d-block">
                    <div className="w-100 d-flex align-items-center justify-content-center text-danger">
                        <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action me-1" color="#dc3545" />
                        <span className="mx-2">{ t('crm-views.incident.errors.step2.part') }</span>
                        <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action me-1" color="#dc3545" />
                    </div>
                </div>
            }
        </div>
    );
}
