import { faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function Step3 ({
    register,
    errors
}) {
    const { t } = useTranslation();

    useEffect(() => {
    }, [errors]);

    return (
        <div className='w-100'>
            <div className="w-100 d-flex flex-column mb-3 imagenes">
                <div className="alert alert-warning mt-2">
                    <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" color="#ffc107" />
                        <small className="ms-2 mb-0">
                            { t('crm-views.incident.note2.1') } <b>{ t('crm-views.incident.note2.2') }</b> { t('crm-views.incident.note2.3') }
                        </small>
                    </div>
                </div>

                <div className="alert alert-info mt-2">
                    <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faInfoCircle} size="1x" className="action" color="#62B1F6" />
                        <small className="ms-2 mb-0">
                            { t('crm-views.incident.note3.1') } <b>{ t('crm-views.incident.note3.2') }</b>
                        </small>
                    </div>
                </div>

                <label>{t('crm-views.incident.select-images')}</label>

                <input type="file" className="form-control" {...register('files[]')} multiple accept="video/*,image/*" />

                { (errors?.fileLengthMin) &&
                    <div className="invalid-feedback required text-center mt-2 d-block">
                        <div className="w-100 d-flex align-items-center justify-content-center text-danger">
                            <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action me-1" color="#dc3545" />
                            <span className="mx-2">{t('crm-views.incident.errors.step3.fileLengthMin')}</span>
                            <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action me-1" color="#dc3545" />
                        </div>
                    </div>
                }

                { (errors?.fileLengthMax) &&
                    <div className="invalid-feedback max-length text-center mt-2  d-block">
                        <div className="w-100 d-flex align-items-center justify-content-center text-danger">
                            <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action me-1" color="#dc3545" />
                            <span className="mx-2">{t('crm-views.incident.errors.step3.fileLengthMax')}</span>
                            <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action me-1" color="#dc3545" />
                        </div>
                    </div>
                }

                { (errors?.maxFileSize) &&
                    <div className="invalid-feedback max-size text-center mt-2  d-block">
                        <div className="w-100 d-flex align-items-center justify-content-center text-danger">
                            <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action me-1" color="#dc3545" />
                            <span className="mx-2">{t('crm-views.incident.errors.step3.maxFileSize')}</span>
                            <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action me-1" color="#dc3545" />
                        </div>
                    </div>
                }

                { (errors?.fileExt) &&
                    <div className="invalid-feedback wrong-ext text-center mt-2  d-block">
                        <div className="w-100 d-flex align-items-center justify-content-center text-danger">
                            <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action me-1" color="#dc3545" />
                            <span className="mx-2">{t('crm-views.incident.errors.step3.fileExt')}</span>
                            <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action me-1" color="#dc3545" />
                        </div>
                    </div>
                }
            </div>

            <div className="w-100 d-flex flex-column mt-3 mb-3 observaciones">
                <label>{t('crm-views.incident.observations')}</label>
                <textarea className="form-control" {...register('comentarios_cliente')} rows="4"></textarea>

                { (errors?.observaciones) &&
                    <div className="invalid-feedback text-center mt-2 d-block">
                        <div className="w-100 d-flex align-items-center justify-content-center text-danger">
                            <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action me-1" color="#dc3545" />
                            <span className="mx-2">{t('crm-views.incident.errors.step3.observations')}</span>
                            <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action me-1" color="#dc3545" />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
