import { useEffect } from 'react';
import './header.scss';
import logo from '../../../../../images/recomotor_logo.png';

export default function Header () {
    useEffect(() => {
    }, []);

    return (
        <nav className='navbar navbar-expand-lg navbar-light bg-light recomotorHeader'>
            <div className='container'>
                <div className='d-flex align-items-center'>
                    <a className='navbar-brand' href='https://recomotor.com'>
                        <img src={logo} alt='Recomotor' className='recomotorHeader-logo' />
                    </a>
                </div>
            </div>
        </nav>
    );
}
