import { useEffect, useState } from 'react';
import './pagination.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default function Pagination ({ postsPerPage, totalPosts, paginate, currentPage }) {
    const [showLastNumber, setShowLastNumber] = useState(false);
    const [showInitialNumber, setShowInitialNumber] = useState(false);
    const [htmlPageNumbers, setHtmlPageNumbers] = useState([]);
    const lastPage = Math.ceil(totalPosts / postsPerPage);

    useEffect(() => {
        const pageNumbers = [];

        for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = () => {
            let visiblePageNumbers;

            if (currentPage <= 4) {
                visiblePageNumbers = pageNumbers.slice(0, 5);
                setShowLastNumber(pageNumbers.length > 5);
                setShowInitialNumber(false);
            } else if (currentPage >= pageNumbers.length - 3) {
                visiblePageNumbers = pageNumbers.slice(pageNumbers.length - 5);
                setShowLastNumber(false);
                setShowInitialNumber(pageNumbers.length > 5);
            } else {
                visiblePageNumbers = pageNumbers.slice(currentPage - 3, currentPage + 2);
                setShowLastNumber(true);
                setShowInitialNumber(true);
            }

            return visiblePageNumbers.map(number => (
                <li key={number} className={number === currentPage ? 'active' : ''}>
                    <button className="page" onClick={() => paginate(number)}>{number}</button>
                </li>
            ));
        };
        const renderedPageNumbers = renderPageNumbers();
        setHtmlPageNumbers(renderedPageNumbers);
    }, [currentPage, totalPosts, postsPerPage, paginate]);

    return (
        <div className="pagination w-100">
            <div className="me-1">
                <button className="arrow arrow-left d-flex justify-content-center align-items-center" onClick={() => paginate(currentPage - 1)}>
                    <FontAwesomeIcon icon={faChevronLeft} size="1x" className="arrow-icon" color="#215732" />
                </button>
            </div>
            {showInitialNumber && (
                <div>
                    <div className="d-flex align-items-center">
                        <button className="page" onClick={() => paginate(1)}>
                            1
                        </button>
                        ...
                    </div>
                </div>
            )}
            {htmlPageNumbers}
            {showLastNumber && (
                <div>
                    <div className="d-flex align-items-center">
                        ...
                        <button className="page" onClick={() => paginate(lastPage)}>
                            {lastPage}
                        </button>
                    </div>
                </div>
            )}
            <div className="ms-1">
                <button className="arrow arrow-right d-flex justify-content-center align-items-center" onClick={() => paginate(currentPage + 1)}>
                    <FontAwesomeIcon icon={faChevronRight} size="1x" className="arrow-icon" color="#215732" />
                </button>
            </div>
        </div>
    );
}
