export const SidebarData = [
    {
        title: 'Trabaja con nosotros',
        path: '/trabaja-con-nosotros',
        cName: 'nav-item'
    },
    {
        title: 'Vende coche',
        path: '/vende-para-desguace',
        cName: 'nav-item'
    },
    {
        title: 'Blog',
        path: '/blog',
        cName: 'nav-item'
    },
    {
        title: 'Contacto',
        path: '/contacto',
        cName: 'nav-item'
    }
];
