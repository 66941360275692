import { useEffect, useRef, useState } from 'react';
import Footer from '../shared/components/Footer';
import Header from '../shared/components/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { setErrorMessage } from '../../shared/helpers/functionalities';
import { toast } from 'react-toastify';
import { getShipmentInfo, setShipmentInfo } from '../shared/services/proveedores';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export default function ProviderShipmentInfo () {
    const { t } = useTranslation();
    const recaptcha = useRef();
    const { ventaId, proveedorId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [shipment, setShipment] = useState(null);
    const [transportistas, setTransportistas] = useState([]);
    const [multiple, setMultiple] = useState(false);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors }
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            venta_id: ventaId,
            proveedor_id: proveedorId,
            num_seguimiento: '',
            transportista_id: null
        }
    });
    const { fields, append } = useFieldArray({ control, name: 'envio' });

    useEffect(() => {
        const getInfo = async () => {
            const response = await getShipmentInfo(ventaId, proveedorId).catch(function (error) {
                toast.error(setErrorMessage(error));
            });

            if (response && response.success) {
                setLoading(false);
                setTransportistas(response.data.transportistas);
                setShipment(response.data);

                if (response.data.envios.length > 1) {
                    setFields(response.data.envios);
                }
            }
        };

        if (proveedorId && ventaId) {
            getInfo();
        } else toast.error('No hay venta!');
    }, []);

    const setFields = (envios) => {
        envios.forEach(envio => {
            const isIn = fields.find(f => f.envio_id === envio.id);

            if (!isIn) {
                append({
                    envio_id: envio.id,
                    pieza_id: envio.piezas[0].id,
                    num_seguimiento: '',
                    transportista_id: null
                });
            }
        });
    };

    const handleSubmitShipmentInfo = (data, e) => {
        e.preventDefault();

        const captchaValue = recaptcha.current.getValue();
        if (!captchaValue) return toast.error(t('errors.captcha'));

        handleAddShipmentInfo({ ...data, captchaValue });
    };

    const handleAddShipmentInfo = async (data) => {
        const response = await setShipmentInfo(data).catch(function (error) {
            toast.error(setErrorMessage(error));
        });

        if (response && response.success) {
            toast.success(response.message);
            navigate('/gracias', { state: { from: 'providers' } });
        }
    };

    return (
        <div className='w-100 h-100 d-flex flex-column justify-content-between'>
            <Header />

            { (loading)
                ? (
                    <div className="w-100 h-100 d-flex align-items-center justify-content-center my-5">
                        <div className="w-100 h-100 d-flex align-items-center justify-content-center my-5">
                            <span className="my-5">{ t('common.loading') }</span>
                        </div>
                    </div>
                )
                : (
                    <div className='w-100 container d-flex align-items-center justify-content-center mt-5'>
                        <form className="form col-12 col-md-8 col-xl-6" onSubmit={handleSubmit(handleSubmitShipmentInfo)}>
                            <input
                                type="hidden"
                                {...register('venta_id')}
                                defaultValue={ventaId} />
                            <input
                                type="hidden"
                                {...register('proveedor_id')}
                                defaultValue={proveedorId} />

                            <div className='w-100 mb-4'>
                                <p className='text-start'>
                                    { t('common.hi') } <span className="fw-bold">{shipment?.proveedor.nombre}</span>! <br />
                                    { t('crm-views.integrations.note5') }
                                </p>

                                <div className='w-100 mt-4'>
                                    <div className='w-100 table-responsive'>
                                        <table className='table'>
                                            <tbody>
                                                { shipment.piezas.map(pieza => {
                                                    return (
                                                        <tr key={'pieza-' + pieza.id}>
                                                            <td>ID: #{pieza.id_referencia_plataforma}</td>
                                                            <td>{pieza.nombre}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="w-100 d-flex align-items-center mb-4">
                                <input
                                    type="checkbox"
                                    {...register('multiple')}
                                    defaultChecked={multiple}
                                    onChange={(e) => setMultiple(e.target.checked) }
                                />
                                <label className="ms-2 fw-bold">{ t('crm-views.integrations.shipment_question') }</label>
                            </div>

                            { (multiple)
                                ? (
                                    <div className='w-100'>
                                        <div className="form-group">
                                            <label>{ t('crm-views.integrations.carrier') }</label>
                                            <select className="form-control" {...register('transportista_id', { required: true })}>
                                                <option value="">{ t('crm-views.integrations.select-carrier') }</option>
                                                { transportistas?.map(transportista => {
                                                    return (
                                                        <option key={'transportista-' + transportista.id} value={transportista.id}>{transportista.nombre}</option>
                                                    );
                                                })}
                                            </select>

                                            { (errors.transportista_id) &&
                                                <div className="invalid-feedback text-center mt-2 p-3 d-block">
                                                    <div className="w-100 d-flex align-items-center justify-content-center text-danger">
                                                        <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action me-1" color="#dc3545" />
                                                        <span className="mx-2">{ t('crm-views.integrations.errors.carrier') }</span>
                                                        <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action me-1" color="#dc3545" />
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div className="form-group">
                                            <label>{ t('crm-views.integrations.tracking_number') }</label>
                                            <input type="text" className="form-control" {...register('num_seguimiento', { required: true })}/>

                                            { (errors.num_seguimiento) &&
                                                <div className="invalid-feedback text-center mt-2 p-3 d-block">
                                                    <div className="w-100 d-flex align-items-center justify-content-center text-danger">
                                                        <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action me-1" color="#dc3545" />
                                                        <span className="mx-2">{ t('crm-views.integrations.errors.tracking_number') }</span>
                                                        <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action me-1" color="#dc3545" />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                                : (
                                    <div className='w-100'>
                                        { fields?.map((field, i) => {
                                            const envio = shipment.envios.find(e => e.id === field.envio_id);

                                            return (
                                                <div key={field.id}>
                                                    <div className='w-100 mb-3'>
                                                        <small>{envio.codigo_transporte} | ID: #{envio.piezas[0].id_referencia_plataforma} | {envio.piezas[0].nombre}</small>
                                                        <input type="hidden" className="form-control" {...register(`envio.${i}.pieza_id`)} value={ envio.id } />

                                                        <div className="form-group">
                                                            <label>{ t('crm-views.integrations.carrier') }</label>
                                                            <select className="form-control" {...register(`envio.${i}.transportista_id`, { required: true })}>
                                                                <option value="">{ t('crm-views.integrations.select-carrier') }</option>
                                                                { transportistas?.map(transportista => {
                                                                    return (
                                                                        <option key={'transportista-' + transportista.id} value={transportista.id}>{transportista.nombre}</option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>

                                                        <div className="form-group">
                                                            <label>{ t('crm-views.integrations.tracking_number') }</label>
                                                            <input type="text" className="form-control" {...register(`envio.${i}.num_seguimiento`, { required: true })} />
                                                        </div>
                                                    </div>
                                                    { i >= 0 && i < (fields.length - 1) && <hr /> }
                                                </div>
                                            );
                                        })}
                                    </div>
                                )
                            }

                            <div className='w-100 d-flex align-items-center justify-content-center'>
                                <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                            </div>

                            <div className="w-100 d-flex align-items-center justify-content-center mt-5">
                                <button type="submit" className="btn btn-primary order-0 order-md-1">{ t('common.send') }</button>
                            </div>
                        </form>
                    </div>
                )}

            <Footer />
        </div>
    );
}
