import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './formInput.scss';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

const FormInput = ({ id, text, name, type, onChange, value, inputProps, errorMessage }) => {
    return (
        <div className="form-input">
            <input
                required
                type={type}
                name={name}
                placeholder={text}
                id={id}
                value={value}
                onChange={onChange}
                {...inputProps}
            />
            <label htmlFor={id}>{text}</label>
            {errorMessage && <span><FontAwesomeIcon icon={faCircleXmark} />{' '}{errorMessage}</span>}
        </div>
    );
};

export default FormInput;
