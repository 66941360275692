import { faChevronRight, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import SkeletonPosition from '../SkeletonPosition';

const PosicionesActivas = ({ positionsToShow }) => {
    const { t } = useTranslation();

    const openPositionInfo = (url) => {
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = url;
        link.click();
    };

    return (
        <section className='posiciones-activas'>
            <h5>{t('trabaja.ofertas.title')}</h5>
            { positionsToShow?.map(position =>
                <div key={position.id}
                    className='posiciones-activas__position-container'
                    onClick={() => openPositionInfo(position.link)}>
                    <div className='posiciones-activas__position-container--text'>
                        <h6 className='posiciones-activas__position-container--text--title'>{position.position_title}</h6>
                        <p className='posiciones-activas__position-container--text--location'>
                            <span className='posiciones-activas__position-container--text--location--icon'><FontAwesomeIcon icon={faLocationDot} /></span>
                            <span className='posiciones-activas__position-container--text--location--text'>{position.position_job_location} {position.position_job_location_remote === 'on' ? '(Remoto)' : ''}</span>
                        </p>
                    </div>
                    <span className='posiciones-activas__position-container--arrow'><FontAwesomeIcon icon={faChevronRight} /></span>
                </div>)
            }
            {!positionsToShow && <><SkeletonPosition/><SkeletonPosition/><SkeletonPosition/><SkeletonPosition/><SkeletonPosition/><SkeletonPosition/></>}
            {positionsToShow?.length === 0 && <p className='posiciones-activas__no-ofertas'>{t('trabaja.ofertas.no-ofertas')}</p>}
        </section>
    );
};

export default PosicionesActivas;
