import API from '../../../../services/api';

export const sendCarDataForValuation = async (data) => {
    return await API.post('/vehiculos', data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(res => res.data)
        .catch(error => error.response);
};

export const getMarcas = async () => {
    return await API.get('/marcas').then(res => res.data);
};

export const getModelos = async () => {
    return await API.get('/modelos').then(res => res.data);
};
