import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
// Config para localización
import './translations/config';

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker
            .register('/sw.js')
            .then((registration) => {
                if (process.env.NODE_ENV === 'development') {
                    console.log(
                        'Service Worker registrado con éxito:',
                        registration
                    );
                }
            })
            .catch((error) => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Error al registrar el Service Worker:', error);
                }
            });
    });
}

const history = createBrowserHistory();
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <StrictMode>
        <BrowserRouter location={history.location} navigator={history}>
            <App />
        </BrowserRouter>
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
