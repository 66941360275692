import pieza1 from '../../../../../../images/piezas/pieza1.png';
import pieza2 from '../../../../../../images/piezas/pieza2.png';
import pieza3 from '../../../../../../images/piezas/pieza3.png';
import pieza4 from '../../../../../../images/piezas/pieza4.png';
import pieza5 from '../../../../../../images/piezas/pieza5.png';
import pieza6 from '../../../../../../images/piezas/pieza6.png';
import pieza7 from '../../../../../../images/piezas/pieza7.png';

export default function Piezas () {
    return (
        <div className="seccion w-100">
            <div className="w-100 ">
                <div className="w-100 d-flex justify-content-between align-items-start piezas">
                    <div className="pieza">
                        <img src={pieza1} className='pieza_1' alt="Compresor aire acondicionado" />
                    </div>

                    <div className="pieza">
                        <img src={pieza2} alt="Cuadro de instrumentos" />
                    </div>

                    <div className="pieza">
                        <img src={pieza3} alt="Motor de arranque" />
                    </div>

                    <div className="pieza">
                        <img src={pieza4} alt="Caudalímetro" />
                    </div>

                    <div className="pieza">
                        <img src={pieza5} alt="Centralita airbag" />
                    </div>

                    <div className="pieza">
                        <img src={pieza6} alt="Caja de mariposa" />
                    </div>

                    <div className="pieza">
                        <img src={pieza7} className='pieza_7' alt="Centralita motor" />
                    </div>
                </div>
            </div>
        </div>
    );
}
