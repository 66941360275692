export const handleValidPhone = (e) => {
    if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
        e.preventDefault();
    }
    if (
        e.target.value.length >= 15 &&
        e.key !== 'Backspace' &&
        e.key !== 'Tab'
    ) {
        e.preventDefault();
    }
};
