import { useTranslation } from 'react-i18next';
import FormClient from './components/FormClient';
import SentDone from './components/SentDone';
import logo from '../../images/recomotor_logo.png';
import { useState } from 'react';

import './sendBudget.scss';

const SendBudget = () => {
    const { t } = useTranslation();
    const [wasSentEmail, setWasSentEmail] = useState(false);
    return (
        <>
            <div className='send-budget'>
                <div className='send-budget__header'>
                    <img src={logo} className='send-budget__header--img' alt="Recomotor" />
                    <h1>{t('budget.title')}</h1>
                </div>
                { wasSentEmail
                    ? <SentDone />
                    : <FormClient setWasSentEmail={setWasSentEmail} />
                }
            </div>
        </>
    );
};

export default SendBudget;
