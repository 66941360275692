import { useTranslation } from 'react-i18next';
import './stars.scss';

const Stars = ({ name, handleStars }) => {
    const { t } = useTranslation();

    return (
        <div className="rating">
            <input type="radio" id={`star5_${name}`} name={name} value="5" onChange={handleStars} />
            <label htmlFor={`star5_${name}`} title={t('surveys.stars.5')}>★</label>
            <input type="radio" id={`star4_${name}`} name={name} value="4" onChange={handleStars} />
            <label htmlFor={`star4_${name}`} title={t('surveys.stars.4')}>★</label>
            <input type="radio" id={`star3_${name}`} name={name} value="3" onChange={handleStars} />
            <label htmlFor={`star3_${name}`} title={t('surveys.stars.3')}>★</label>
            <input type="radio" id={`star2_${name}`} name={name} value="2" onChange={handleStars} />
            <label htmlFor={`star2_${name}`} title={t('surveys.stars.2')}>★</label>
            <input type="radio" id={`star1_${name}`} name={name} value="1" onChange={handleStars} />
            <label htmlFor={`star1_${name}`} title={t('surveys.stars.1')}>★</label>
        </div>
    );
};

export default Stars;
