import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import heroImg from '../../../../../../images/hero_home.png';

export default function Hero () {
    const { t } = useTranslation();

    const [loaded, setLoaded] = useState(false);

    const loadHeroImg = () => {
        const img = new Image();
        img.src = heroImg;
        img.onload = () => {
            setLoaded(true);
        };
    };

    useEffect(() => {
        loadHeroImg();
    }, []);

    return (
        <>
            <div
                style={{
                    opacity: loaded ? 1 : 0,
                    transition: 'opacity 0.5 ease',
                    backgroundImage: loaded ? `url(${heroImg})` : 'none'
                }}
                className="hero home d-flex flex-column align-items-center justify-content-center position-relative">
                <h1>{t('home.hero.title')}</h1>
            </div>
            <div className="container hero-highlighted-container">
                <div style={{ opacity: 0 }}></div>
                <div className="hero-highlighted">
                    <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                        <p>
                            <b>{t('home.hero.hero-highlighted.text-1.1')}</b> {t('home.hero.hero-highlighted.text-1.2')}
                        </p>

                        <span className="divider"></span>

                        <p>
                            <b>{t('home.hero.hero-highlighted.text-2.1')}</b> {t('home.hero.hero-highlighted.text-2.2')}
                        </p>

                        <span className="divider"></span>

                        <p>
                            {t('home.hero.hero-highlighted.text-3.1')} <b>{t('home.hero.hero-highlighted.text-3.2')}</b>
                        </p>

                        <span className="divider"></span>

                        <p>
                            {t('home.hero.hero-highlighted.text-4.1')} <b>{t('home.hero.hero-highlighted.text-4.2')}</b>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}
