import foto from '../../../../../../images/foto.png';
import { useTranslation } from 'react-i18next';

export default function Wrapper () {
    const { t } = useTranslation();

    return (
        <div className="seccion wrapper w-100 d-flex flex-wrap flex-md-nowrap align-items-center">
            <div className="col-12 col-md-6 pe-0">
                <img src={foto} className="w-100 mx-auto d-block" alt="Recomotor" />
            </div>

            <div className="col-12 col-md-6 p-4 pt-5 p-lg-5 wrapper-text">
                <h2 className="fw-bold">{t('home.wrapper.title.1')} <br /> {t('home.wrapper.title.2')}</h2>
                <p className="text-start mt-4">
                    {t('home.wrapper.text')}
                </p>
            </div>
        </div>
    );
}
