import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import Spinner from '../../../Spinner';
import './altaTallerForm.scss';

// eslint-disable-next-line react/prop-types
export default function AltaTallerForm ({ onSubmitAlta }) {
    const { t } = useTranslation();
    const recaptcha = useRef();
    const conctactoSchema = yup
        .object({
            nombre: yup.string().required('El nombre es obligatorio.'),
            telefono: yup.string().required('El teléfono es obligatorio.'),
            email: yup
                .string()
                .required('El email es obligatorio.')
                .email('Formato de email incorrecto.')
        })
        .required();
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(conctactoSchema),
        defaultValues: {
            nombre: '',
            email: '',
            telefono: ''
        }
    });
    const [condiciones, setCondiciones] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {}, []);

    const handleChangeCondiciones = (e) => {
        setCondiciones(e.target.checked);
    };

    const onSubmit = async (data, e) => {
        e.preventDefault();
        const captchaValue = recaptcha.current.getValue();
        if (!captchaValue) return toast.error(t('errors.captcha'));
        setIsLoading(true);
        await onSubmitAlta({ ...data, captchaValue });
        setTelefonoValue('');
        reset();
        setIsLoading(false);
    };

    const [telefonoValue, setTelefonoValue] = useState('');

    const handleTelefonoChange = (event) => {
        let inputValue = event.target.value;
        if (!/^[0-9()+-]*$/.test(inputValue)) {
            inputValue = inputValue.slice(0, -1);
        }
        setTelefonoValue(inputValue);
    };

    return (
        <div className="w-100 d-flex flex-column align-items-center alta-taller-form">
            <form className="form w-100" onSubmit={handleSubmit(onSubmit)}>
                <div className="w-100 d-flex flex-column">
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            {...register('nombre', { maxLength: 50, required: true })}
                            placeholder={t('alta-taller-modal.placeholder.1')}
                            defaultValue={''}
                        />
                        <div className="form-invalid">
                            {errors.nombre && (
                                <div className="w-100 mt-2">
                                    <FontAwesomeIcon
                                        icon={faTimesCircle}
                                        size="1x"
                                        className="action me-1"
                                        color="#dc3545"
                                    />
                                    {t('alta-taller-modal.errors-form.nombre')}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            {...register('telefono', { maxLength: 20, minLength: 9, required: true })}
                            placeholder={t('alta-taller-modal.placeholder.2')}
                            onChange={handleTelefonoChange}
                            value={telefonoValue}
                            minLength={9}
                            maxLength={20}
                        />

                        <div className="form-invalid">
                            {errors.telefono && (
                                <div className="w-100 mt-2">
                                    <FontAwesomeIcon
                                        icon={faTimesCircle}
                                        size="1x"
                                        className="action me-1"
                                        color="#dc3545"
                                    />
                                    {t('alta-taller-modal.errors-form.tlf')}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="form-group">
                        <input
                            type="email"
                            className="form-control"
                            placeholder={t('alta-taller-modal.placeholder.3')}
                            {...register('email', { required: true })}
                            defaultValue={''}
                        />

                        <div className="form-invalid">
                            {errors.email && (
                                <div className="w-100 mt-2">
                                    <FontAwesomeIcon
                                        icon={faTimesCircle}
                                        size="1x"
                                        className="action me-1"
                                        color="#dc3545"
                                    />
                                    {t(`alta-taller-modal.errors-form.${errors.email.message}`)}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="w-100 d-flex align-items-center my-3">
                        <div className="form-check">
                            <input
                                type="checkbox"
                                name="condiciones"
                                id="condiciones"
                                className="form-check-input"
                                onChange={handleChangeCondiciones}
                                defaultChecked={false}
                            />
                            <small className="form-check-label" htmlFor="condiciones">
                                {t('alta-taller-modal.checkbox.1')}{' '}
                                <Link
                                    to={'/politica-privacidad'}
                                    title="Política de privacidad"
                                    className="fw-bold"
                                >
                                    {t('alta-taller-modal.checkbox.2')}
                                </Link>{' '}
                                {t('alta-taller-modal.checkbox.3')}{' '}
                                <Link
                                    to={'/condiciones-generales'}
                                    title="Condiciones generales"
                                    className="fw-bold"
                                >
                                    {t('alta-taller-modal.checkbox.4')}
                                </Link>
                            </small>
                        </div>
                    </div>
                </div>
                <div className='w-100 d-flex align-items-center justify-content-center pt-2 pb-3'>
                    <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                </div>
                <div className="w-100 d-flex align-items-center justify-content-center mt-3">
                    <button
                        type="submit"
                        className="btn btn-primary alta-taller-form__btn"
                        disabled={condiciones === false}
                    >
                        {isLoading ? <Spinner /> : t('alta-taller-modal.button')}
                    </button>
                </div>
            </form>
        </div>
    );
}
