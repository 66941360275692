import { useTranslation } from 'react-i18next';

const AvisoLegal = () => {
    const { t } = useTranslation();
    return (
        <div className="w-100 h-100 d-flex flex-column px-4 px-md-0 pt-4">
            <div className="w-100 d-flex align-items-center justify-content-center">
                <div className="col-12 col-md-8 col-lg-5 content-info mt-4 mb-5">
                    <h1>{t('legal.title')}</h1>
                    <h4>{t('legal.ley.title')}</h4>
                    <p>
                        {t('legal.ley.text.1')} <br />
                        {t('legal.ley.text.2')}
                    </p>

                    <h5 className='mt-3'>{t('legal.datos.title')}</h5>

                    <div>
                        <p><b>{t('legal.datos.dominio')}</b> www.recomotor.com</p>
                        <p><b>{t('legal.datos.comercial')}</b> Recomotor</p>
                        <p><b>{t('legal.datos.denominacion')}</b> JG RECOMOTOR S.L.</p>
                        <p><b>{t('legal.datos.nif')}</b> B04950093</p>
                        <p><b>{t('legal.datos.direccion')} </b>Polígono dels Frares, Calle D, parcela número 46, 25190 Lleida</p>
                        <p><b>{t('legal.datos.tlf')}</b> +34 644 175 425</p>
                        <p><b>{t('legal.datos.email')}</b> info@recomotor.com</p>

                        <p className="mt-2">{t('legal.datos.inscrita')}</p>
                    </div>

                    <h4 className='mt-3'>{t('legal.derechos.title')}</h4>
                    <p>
                        {t('legal.derechos.text.1')} <a href="https://www.recomotor.com" target="_blank" rel="noreferrer">www.recomotor.com</a>.
                        { ' '}
                        {t('legal.derechos.text.2')}
                    </p>

                    <h4 className='mt-3'>{t('legal.exencion.title')}</h4>
                    <p>{t('legal.exencion.text')}</p>

                    <h4>{t('legal.cookies.title')}</h4>
                    <p>
                        {t('legal.cookies.text')}
                    </p>

                    <h4>{t('legal.enlaces.title')}</h4>
                    <p>
                        {t('legal.enlaces.text')}
                    </p>

                    <h4>{t('legal.ip.title')}</h4>
                    <p>{t('legal.ip.text')}</p>

                    <h4 className='mt-3'>{t('legal.aplicable.title')}</h4>
                    <p>{t('legal.aplicable.text')}</p>
                </div>
            </div>
        </div>
    );
};

export default AvisoLegal;
