import './home.scss';
import Procedimiento from './components/Procedimiento';
import Hero from './components/Hero';
import Confian from './components/Confian';
import Hero2 from './components/Hero2';
import EsTaller from './components/EsTaller';
import Piezas from './components/Piezas';
import Wrapper from './components/Wrapper';

export default function Home ({ onOpenAltaTaller }) {
    return (
        <div className="w-100 home">
            <Hero />
            <EsTaller onOpenAltaTaller={onOpenAltaTaller} />
            <Hero2 />
            <Piezas />
            <Procedimiento />
            <Wrapper />
            <Confian />
        </div>
    );
}
