// eslint-disable-next-line promise/param-names
export const delay = ms => new Promise(res => setTimeout(res, ms));

export const headers = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
};

export const setErrorMessage = (error) => {
    if (error.response) {
        return error.response.data.message ? error.response.data.message : error.response.data.error;
    } else if (error.request) {
        return 'Ups... algo ha ido mal';
    } else {
        return error.message;
    }
};

export const isBoolean = val => typeof val === 'boolean';

export const isDuplicate = (item, arr) => {
    return arr.some(el => item.nombre.toUpperCase() === el.nombre.toUpperCase() && levenshteinDistance(item.direccion.toUpperCase(), el.direccion.toUpperCase()) === 0);
};

export const levenshteinDistance = (str1 = '', str2 = '') => {
    const track = Array(str2.length + 1).fill(null).map(() =>
        Array(str1.length + 1).fill(null));
    for (let i = 0; i <= str1.length; i += 1) {
        track[0][i] = i;
    }
    for (let j = 0; j <= str2.length; j += 1) {
        track[j][0] = j;
    }
    for (let j = 1; j <= str2.length; j += 1) {
        for (let i = 1; i <= str1.length; i += 1) {
            const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
            track[j][i] = Math.min(
                track[j][i - 1] + 1, // deletion
                track[j - 1][i] + 1, // insertion
                track[j - 1][i - 1] + indicator // substitution
            );
        }
    }
    return track[str2.length][str1.length];
};
