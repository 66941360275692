import './symbolCheck.scss';

const SymbolCheck = () => {
    return (
        <div className="success-icon">
            <div className="success-icon__tip"></div>
            <div className="success-icon__long"></div>
        </div>
    );
};

export default SymbolCheck;
