import { useTranslation } from 'react-i18next';
import AltaTallerForm from '../../shared/components/AltaTallerModal/components/AltaTallerForm';
import './altaTaller.scss';
import { sendWorkshopRegistration } from '../../shared/components/AltaTallerModal/services/alta_taller';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const AltaTaller = () => {
    const { t } = useTranslation();
    const urlParams = new URLSearchParams(window.location.search);
    const origin = urlParams.get('origin');
    const navigate = useNavigate();

    const handleSubmitAltaTaller = async (data) => {
        const response = await sendWorkshopRegistration({ ...data, origin });

        if (response) {
            if (response.success) {
                toast.success(response.message);
                setTimeout(() => {
                    navigate('/');
                }, 2000);
            } else {
                toast.error(response?.data?.error || t('errors.1'));
            }
            return response.success;
        } else {
            return false;
        }
    };

    return <div className='alta-taller'>
        <div className="w-100 d-flex flex-column align-items-center mb-5">
            <h4>{t('alta-taller-modal.title')}</h4>
            <p className="mt-2 mb-3">{t('alta-taller-modal.subtitle')}</p>

            <div className="col-12 mt-4">
                <AltaTallerForm
                    onSubmitAlta={handleSubmitAltaTaller}
                />
            </div>
        </div>
    </div>;
};

export default AltaTaller;
