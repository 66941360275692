import { useTranslation } from 'react-i18next';
import SymbolCheck from '../../../Surveys/shared/components/SymbolCheck';

const SentDone = () => {
    const { t } = useTranslation();
    return (
        <div className='survey-done sent-done'>
            <div className='survey-done__container sent-done__container'>
                <SymbolCheck />
                <div className='survey-done__container--text'>
                    <h2 className='survey-done__container--text--h2'>{t('budget.sent')}</h2>
                </div>
            </div>
        </div>
    );
};

export default SentDone;
