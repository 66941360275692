import recambios from '../../../../../../images/eco.png';
import coches from '../../../../../../images/coche.png';
import dinero from '../../../../../../images/monedas.png';
import { useTranslation } from 'react-i18next';

export default function Features () {
    const { t } = useTranslation();
    return (
        <div className="seccion container">
            <div className="row g-3">
                <div className="col-12 col-md-4 d-flex flex-column align-items-center justify-content-center">
                    <div className="feature-item feature-item-feature d-flex flex-column align-items-center justify-content-center">
                        <img src={recambios} alt="Tasamos tu vehículo" />
                        <h4 className="m-3">{t('vende.features.text1.1')} <br /> {t('vende.features.text1.2')}</h4>
                    </div>
                </div>
                <div className="col-12 col-md-4 d-flex flex-column align-items-center justify-content-center">
                    <div className="feature-item feature-item-feature d-flex flex-column align-items-center justify-content-center">
                        <img src={coches} alt="Recogida y tramitación de baja" />
                        <h4 className="m-3">{t('vende.features.text2.1')}  <br /> {t('vende.features.text2.2')}</h4>
                    </div>
                </div>
                <div className="col-12 col-md-4 d-flex flex-column align-items-center justify-content-center">
                    <div className="feature-item feature-item-feature d-flex flex-column align-items-center justify-content-center">
                        <img src={dinero} alt="Mejor Precio" />
                        <h4 className="m-3">{t('vende.features.text3.1')} <br /> {t('vende.features.text3.2')}</h4>
                    </div>
                </div>
            </div>
        </div>
    );
}
