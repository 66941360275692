import API from '../../../../services/api';

export const getShipmentInfo = async (ventaId, providerId) => {
    return await API.get('/providers/shipment-info/' + ventaId + '/' + providerId)
        .then(res => res.data);
};

export const getSaleInfo = async (ventaId, providerId) => {
    return await API.get('/providers/sale-info/' + ventaId + '/' + providerId)
        .then(res => res.data);
};

export const getSaleInfoVO = async (ventaId, providerId) => {
    return await API.get('/providers-vo/sale-info/' + ventaId + '/' + providerId)
        .then(res => res.data);
};

export const setShipmentInfo = async (data) => {
    return await API.post('/providers/shipment-info', data)
        .then(res => res.data)
        .catch(error => error.message);
};
