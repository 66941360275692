import { useEffect } from 'react';
import './step1.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

export default function Step1 ({
    register,
    tipoIncidencias,
    errors
}) {
    const { t } = useTranslation();

    useEffect(() => {
    }, [errors]);

    return (
        <div className='w-100'>
            <div className="w-100 d-flex flex-column mb-3">
                <p className='text-start'>{ t('crm-views.incident.select-reason')}</p>

                <div className="w-100 d-flex flex-wrap align-items-stretch justify-content-center mt-3" style={{ gap: '13px' }}>
                    { tipoIncidencias.map(tipo => {
                        return (
                            <label key={'tipo-' + tipo.id} className="col-12 col-md-5 tipoIncidencia-container">
                                <input
                                    type="radio"
                                    {...register('tipo_incidencia_id')}
                                    value={tipo.id}
                                    autoComplete="off" />

                                <div className="d-flex flex-column align-items-center justify-content-center tipoIncidencia-container-item">
                                    <h4 className='text-center'>{ t(`crm-views.incident.reasons.${tipo.id}.name`) }</h4>
                                    <p>{ t(`crm-views.incident.reasons.${tipo.id}.info`) }</p>
                                </div>
                            </label>
                        );
                    }) }
                </div>
            </div>

            { (errors) &&
                <div className="invalid-feedback text-center mt-2 p-3 d-block">
                    <div className="w-100 d-flex align-items-center justify-content-center text-danger">
                        <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action me-1" color="#dc3545" />
                        <span className="mx-2">{ t('crm-views.incident.errors.step1.reason') }</span>
                        <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="action me-1" color="#dc3545" />
                    </div>
                </div>
            }
        </div>
    );
}
