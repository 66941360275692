import rodi from '../../../../../../images/empresas/rodi.png';
import flexicar from '../../../../../../images/empresas/flexicar.png';
import euromaster from '../../../../../../images/empresas/euromaster.png';
import clicars from '../../../../../../images/empresas/clicars.png';
import compramostucoche from '../../../../../../images/empresas/compramostucoche.png';
import mobius from '../../../../../../images/empresas/mobius.png';
import mutuamadrileña from '../../../../../../images/empresas/mutuamadrileña.png';
import okmobility from '../../../../../../images/empresas/okmobility.png';
import quadis from '../../../../../../images/empresas/quadis.png';
import seligrat from '../../../../../../images/empresas/seligrat.png';
import casals from '../../../../../../images/empresas/casals.png';
import davima from '../../../../../../images/empresas/davima.png';
import enisa from '../../../../../../images/empresas/enisa.png';
import cdti from '../../../../../../images/empresas/cdti.png';
import ue from '../../../../../../images/empresas/ue.png';
import soc from '../../../../../../images/empresas/soc.png';
import next from '../../../../../../images/empresas/next.png';
import aedra from '../../../../../../images/empresas/aedra.png';
import sigrauto from '../../../../../../images/empresas/sigrauto.png';
import { useTranslation } from 'react-i18next';

export default function Confian () {
    const { t } = useTranslation();
    return (
        <div className="container w-100 seccion confian-en-nosotros">
            <h2 className="text-center mb-4">
                <b>{t('home.confian.title.1')}</b> <br /> {t('home.confian.title.2')}
            </h2>

            <div className="d-flex flex-wrap">
                <div className="col-6 col-sm-4 col-lg-3 d-flex align-items-center justify-content-center each-div-brand">
                    <img src={rodi} className="brand" id='rodi' alt="Rodi" loading='lazy' />
                </div>
                <div className="col-6 col-sm-4 col-lg-3 d-flex align-items-center justify-content-center each-div-brand">
                    <img src={quadis} className="brand" id='quadis' alt="Quadis" loading='lazy' />
                </div>
                <div className="col-6 col-sm-4 col-lg-3 d-flex align-items-center justify-content-center each-div-brand">
                    <img src={clicars} className="brand" id='clicars' alt="Clicars" loading='lazy' />
                </div>
                <div className="col-6 col-sm-4 col-lg-3 d-flex align-items-center justify-content-center each-div-brand">
                    <img src={flexicar} className="brand" id='flexicar' alt="Flexicar" loading='lazy' />
                </div>
                <div className="col-6 col-sm-4 col-lg-3 d-flex align-items-center justify-content-center each-div-brand">
                    <img src={okmobility} className="brand" id='okmobility' alt="Ok Mobility" loading='lazy' />
                </div>
                <div className="col-6 col-sm-4 col-lg-3 d-flex align-items-center justify-content-center each-div-brand">
                    <img src={seligrat} className="brand" id='seligrat' alt="Seligrat" loading='lazy' />
                </div>
                <div className="col-6 col-sm-4 col-lg-3 d-flex align-items-center justify-content-center each-div-brand">
                    <img src={euromaster} className="brand" id='euromaster' alt="Ad Parts" loading='lazy' />
                </div>
                <div className="col-6 col-sm-4 col-lg-3 d-flex align-items-center justify-content-center each-div-brand">
                    <img src={davima} className="brand" id='davima' alt="Davima" loading='lazy' />
                </div>
                <div className="col-6 col-sm-4 col-lg-3 d-flex align-items-center justify-content-center each-div-brand">
                    <img src={compramostucoche} className="brand" id='compramostucoche' alt="CompramosTuCoche.ES" loading='lazy' />
                </div>
                <div className="col-6 col-sm-4 col-lg-3 d-flex align-items-center justify-content-center each-div-brand">
                    <img src={mutuamadrileña} className="brand" id='mutuamad' alt="Mutua Madrileña" loading='lazy' />
                </div>
                <div className="col-6 col-sm-4 col-lg-3 d-flex align-items-center justify-content-center each-div-brand">
                    <img src={casals} className="brand" id='casals' alt="Casals" loading='lazy' />
                </div>
                <div className="col-6 col-sm-4 col-lg-3 d-flex align-items-center justify-content-center each-div-brand">
                    <img src={mobius} className="brand" id='mobius' alt="Mobius Group" loading='lazy' />
                </div>
            </div>
            <div className="d-flex flex-wrap empresas-publicas">
                <div className='d-flex flex-wrap empresas-publicas__container'>
                    <div className="align-items-center justify-content-center">
                        <img src={ue} className="ue" id='ue' alt="ue" loading='lazy' />
                    </div>
                    <div className="align-items-center justify-content-center">
                        <img src={soc} className="soc" id='soc' alt="soc" loading='lazy' />
                    </div>
                    <div className="align-items-center justify-content-center">
                        <img src={next} className="next" id='next' alt="next" loading='lazy' />
                    </div>
                    <div className="align-items-center justify-content-center">
                        <img src={cdti} className="cdti" id='cdti' alt="Cdti" loading='lazy' />
                    </div>
                    <div className="align-items-center justify-content-center">
                        <img src={enisa} className="enisa" id='enisa' alt="Enisa" loading='lazy' />
                    </div>
                    <div className="align-items-center justify-content-center">
                        <img src={aedra} className="aedra" id='aedra' alt="aedra" loading='lazy' />
                    </div>
                    <div className="align-items-center justify-content-center">
                        <img src={sigrauto} className="sigrauto" id='sigrauto' alt="sigrauto" loading='lazy' />
                    </div>
                </div>
            </div>
        </div>
    );
}
