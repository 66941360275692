import API from '../../../../services/api';

export const sendIntegracionResponse = async (data) => {
    return await API.post('/integraciones/response', data)
        .then(res => res.data);
};

export const sendIntegracionVoResponse = async (data) => {
    return await API.post('/integraciones-vo/response', data)
        .then(res => res.data);
};

export const sendIntegracionResponseData = async (data) => {
    return await API.post('/integraciones/response-data', data)
        .then(res => res.data)
        .catch(error => error.message);
};

export const sendIntegracionVoResponseData = async (data) => {
    return await API.post('/integraciones-vo/response-data', data)
        .then(res => res.data)
        .catch(error => error.message);
};

export const savePartProblem = async (data) => {
    return await API.post('/integraciones/part-problem', data)
        .then(res => res.data)
        .catch(error => error.message);
};

export const saveVoPartProblem = async (data) => {
    return await API.post('/integraciones-vo/part-problem', data)
        .then(res => res.data)
        .catch(error => error.message);
};

export const savePartConfirm = async (data) => {
    return await API.post('/integraciones/part-confirm', data)
        .then(res => res.data)
        .catch(error => error.message);
};

export const saveVoPartConfirm = async (data) => {
    return await API.post('/integraciones-vo/part-confirm', data)
        .then(res => res.data)
        .catch(error => error.message);
};
