import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';

export default function ContactoForm ({
    onSubmitContacto
}) {
    const { t } = useTranslation();
    const recaptcha = useRef();
    const conctactoSchema = yup.object({
        nombre: yup.string().required('El nombre es obligatorio.'),
        email: yup.string().required('El email es obligatorio.').email('Formato de email incorrecto.'),
        telefono: yup.string().required('El teléfono es obligatorio.'),
        mensaje: yup.string().required('El mensaje es obligatorio.')
    }).required();
    const {
        register,
        formState: { errors },
        reset,
        handleSubmit
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(conctactoSchema),
        defaultValues: {
            nombre: '',
            email: '',
            telefono: '',
            mensaje: ''
        }
    });
    const [condiciones, setCondiciones] = useState(false);

    const handleChangeCondiciones = (e) => { setCondiciones(e.target.checked); };

    const onSubmit = async (data, e) => {
        e.preventDefault();
        const captchaValue = recaptcha.current.getValue();
        if (!captchaValue) return toast.error(t('errors.captcha'));
        onSubmitContacto({ ...data, captchaValue });
        setTelefonoValue('');
        reset();
    };

    const [telefonoValue, setTelefonoValue] = useState('');

    const handleTelefonoChange = (event) => {
        let inputValue = event.target.value;
        if (!/^[0-9()+-]*$/.test(inputValue)) {
            inputValue = inputValue.slice(0, -1);
        }
        setTelefonoValue(inputValue);
    };

    return (
        <div className="w-100 mt-5">
            <div className="w-100 d-flex flex-wrap flex-md-nowrap">
                <form className="form w-100" onSubmit={handleSubmit(onSubmit)}>
                    <div className="w-100 d-flex flex-column">
                        <div className="form-group">
                            <input
                                type="tel"
                                className="form-control"
                                {...register('nombre', { required: true })}
                                placeholder={t('contacto.form.placeholder.1')}
                                defaultValue={''} />

                            <div className="form-invalid">
                                {(errors.nombre) &&
                                    <div className="w-100 mt-2">
                                        <FontAwesomeIcon icon={faTimesCircle} size="1x" className="action me-1" color="#dc3545" />
                                        {t('contacto.form.errors.nombre')}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="form-group">
                            <input
                                type="email"
                                className="form-control"
                                placeholder={t('contacto.form.placeholder.2')}
                                {...register('email', { required: true })}
                                defaultValue={''} />

                            <div className="form-invalid">
                                {(errors.email) &&
                                    <div className="w-100 mt-2">
                                        <FontAwesomeIcon icon={faTimesCircle} size="1x" className="action me-1" color="#dc3545" />
                                        {t(`contacto.form.errors.${errors.email.message}`)}
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                {...register('telefono', { maxLength: 20, minLength: 9, required: true })}
                                placeholder={t('contacto.form.placeholder.3')}
                                value={telefonoValue}
                                minLength={9}
                                maxLength={20}
                                onChange={handleTelefonoChange} />

                            <div className="form-invalid">
                                {(errors.telefono) &&
                                    <div className="w-100 mt-2">
                                        <FontAwesomeIcon icon={faTimesCircle} size="1x" className="action me-1" color="#dc3545" />
                                        {t('contacto.form.errors.tlf')}
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="form-group">
                            <textarea
                                type="text"
                                placeholder={t('contacto.form.placeholder.4')}
                                className="form-control"
                                {...register('mensaje', { required: true })}
                                defaultValue={''}></textarea>

                            <div className="form-invalid">
                                {(errors.mensaje) &&
                                    <div className="w-100 mt-2">
                                        <FontAwesomeIcon icon={faTimesCircle} size="1x" className="action me-1" color="#dc3545" />
                                        {t('contacto.form.errors.msg')}
                                    </div>
                                }
                            </div>
                        </div>

                        <div className='w-100 d-flex align-items-center justify-content-center'>
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    name="condiciones"
                                    id="condiciones"
                                    className='form-check-input'
                                    onChange={handleChangeCondiciones}
                                    defaultChecked={false} />
                                <small className="form-check-label" htmlFor="condiciones">
                                    {t('contacto.form.checkbox.1')}{' '}
                                    <Link to={'/politica-privacidad'} title="Política de privacidad" className="fw-bold">
                                        {t('contacto.form.checkbox.2')}
                                    </Link>{' '}
                                    {t('contacto.form.checkbox.3')}{' '}
                                    <Link to={'/condiciones-generales'} title="Condiciones generales" className="fw-bold">
                                        {t('contacto.form.checkbox.4')}
                                    </Link>
                                </small>
                            </div>
                        </div>
                    </div>

                    <div className="w-100 d-flex flex-column align-items-center justify-content-center gap-4 mt-4">
                        <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={condiciones === false}
                        >{t('contacto.form.button')}</button>
                    </div>
                </form>
            </div>
        </div>
    );
}
