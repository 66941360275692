import { Link } from 'react-router-dom';

export default function Post ({
    title,
    resume,
    img,
    slug
}) {
    const gradient = 'linear-gradient(0deg, rgba(33, 87, 50, 0.6), rgba(33, 87, 50, 0.6)), linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))';

    return (
        <div className="col-12 col-sm-6 col-md-4 col-lg-4 post position-relative">
            <Link to={`/post/${slug}`} title="Post">
                <div className="post-img" style={{
                    backgroundImage: `${gradient}, url(${img})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}>
                </div>

                <div className="post-content position-absolute">
                    <div className="post-title">
                        <h4>{title}</h4>
                    </div>
                    <div className="post-body" dangerouslySetInnerHTML={{ __html: resume }}></div>
                </div>
            </Link>
        </div>

    );
}
