import { useTranslation } from 'react-i18next';

const PoliticaCookies = () => {
    const { t } = useTranslation();
    return (
        <div className="w-100 h-100 d-flex flex-column px-4 px-md-0 pt-4">
            <div className="w-100 d-flex align-items-center justify-content-center">
                <div className="col-12 col-md-8 col-lg-5 content-info mt-4 mb-5">
                    <h1>{t('cookies.title')}</h1>

                    <h4>{t('cookies.info-general.title')}</h4>
                    <p>
                        {t('cookies.info-general.text.1')}
                    </p>

                    <p>
                        {t('cookies.info-general.text.2')} <a href="https://www.recomotor.com" target="_blank" rel="noreferrer">www.recomotor.com</a> {t('cookies.info-general.text.3')}
                    </p>

                    <p>
                        {t('cookies.info-general.text.4')}
                    </p>

                    <p>
                        {t('cookies.info-general.text.5')}
                    </p>

                    <p>
                        {t('cookies.info-general.text.6')}
                    </p>

                    <h4>{t('cookies.utilizacion.title')}</h4>
                    <p>
                        {t('cookies.utilizacion.text.1')} <a href="https://www.recomotor.com" target="_blank" rel="noreferrer">www.recomotor.com</a> {t('cookies.utilizacion.text.2')}
                    </p>
                    <p>
                        {t('cookies.utilizacion.text.3')}
                    </p>

                    <h4>{t('cookies.tipos.title')}</h4>
                    <p>
                        {t('cookies.tipos.text.1')}
                    </p>
                    <p>
                        {t('cookies.tipos.text.2')}
                    </p>

                    <div>
                        <ul>
                            <li>
                                <b>{t('cookies.tipos.sub-text.rendimiento.title')} </b> {t('cookies.tipos.sub-text.rendimiento.text.1')}

                                <ul>
                                    <li>{t('cookies.tipos.sub-text.rendimiento.text.2')}</li>
                                    <li>{t('cookies.tipos.sub-text.rendimiento.text.3')}</li>
                                </ul>
                            </li>
                            <li>
                                <b>{t('cookies.tipos.sub-text.geolocalizacion.title')}</b> {t('cookies.tipos.sub-text.geolocalizacion.text.1')}
                            </li>
                            <li>
                                <b>{t('cookies.tipos.sub-text.registro.title')}</b> {t('cookies.tipos.sub-text.registro.text.1')}

                                <ul>
                                    <li>
                                        {t('cookies.tipos.sub-text.registro.text.2')}
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>{t('cookies.tipos.sub-text.redes-sociales.title')}</b> {t('cookies.tipos.sub-text.redes-sociales.text.1')}

                                <ul>
                                    <li>
                                        <b>Facebook</b>: <a href="https://www.facebook.com/policies/cookies/" target="_blank" rel="noreferrer">https://www.facebook.com/policies/cookies/</a>
                                    </li>
                                    <li>
                                        <b>Instagram</b>: <a href="https://help.instagram.com/1896641480634370?ref=ig" target="_blank" rel="noreferrer">https://help.instagram.com/1896641480634370?ref=ig</a>
                                    </li>
                                    <li>
                                        <b>Twitter</b>: <a href="https://twitter.com/es/privacy" target="_blank" rel="noreferrer">https://twitter.com/es/privacy</a>
                                    </li>
                                    <li>
                                        <b>Youtube</b>: <a href="https://policies.google.com/privacy?hl=es-419&gl=mx" target="_blank" rel="noreferrer">https://policies.google.com/privacy?hl=es-419&gl=mx</a>
                                    </li>
                                    <li>
                                        <b>Google+</b>: <a href="https://policies.google.com/technologies/cookies?hl=es" target="_blank" rel="noreferrer">https://policies.google.com/technologies/cookies?hl=es</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>{t('cookies.tipos.sub-text.analitica.title')}</b> {t('cookies.tipos.sub-text.analitica.text.1')} <a href="https://www.recomotor.com" target="_blank" rel="noreferrer">www.recomotor.com</a> {t('cookies.tipos.sub-text.analitica.text.2')}

                                <ul>
                                    <li>{t('cookies.tipos.sub-text.analitica.text.3')}</li>
                                    <li>{t('cookies.tipos.sub-text.analitica.text.4')}</li>
                                    <li>{t('cookies.tipos.sub-text.analitica.text.5')}</li>
                                    <li>{t('cookies.tipos.sub-text.analitica.text.6')}</li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <p>
                        <b>{t('cookies.tipos.sub-text.analitica.importante.title')}</b> {t('cookies.tipos.sub-text.analitica.importante.text')}
                    </p>

                    <h4>{t('cookies.aceptacion.title')}</h4>
                    <p>
                        {t('cookies.aceptacion.text.1')}
                    </p>
                    <p>
                        {t('cookies.aceptacion.text.2')}
                    </p>
                    <p>
                        {t('cookies.aceptacion.text.3')}
                    </p>
                    <ul>
                        <li>
                            <b>Internet Explorer</b>: <a href="http://windows.microsoft.com/es-ES/windows-vista/Block-or-allow-cookies" target="_blank" rel="noreferrer">http://windows.microsoft.com/es-ES/windows-vista/Block-or-allow-cookies</a>
                        </li>
                        <li>
                            <b>Google Chrome</b>: <a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank" rel="noreferrer">https://support.google.com/chrome/answer/95647?hl=es</a>
                        </li>
                        <li>
                            <b>Firefox</b>: <a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we" target="_blank" rel="noreferrer">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</a>
                        </li>
                        <li>
                            <b>Safari</b>: <a href="http://support.apple.com/kb/HT1677?viewlocale=es_ES" target="_blank" rel="noreferrer">http://support.apple.com/kb/HT1677?viewlocale=es_ES</a>
                        </li>
                    </ul>

                    <p>
                        {t('cookies.aceptacion.text.4')}
                    </p>

                    <h4>{t('cookies.relacion.title')}</h4>
                    <p>
                        {t('cookies.relacion.text.1')}
                    </p>

                    <div>
                        <ul>
                            <li>
                                <b>{t('cookies.relacion.sub-text.propias.title')}</b> {t('cookies.relacion.sub-text.propias.text')}
                            </li>

                            <li>
                                <b>{t('cookies.relacion.sub-text.terceros.title')}</b> {t('cookies.relacion.sub-text.terceros.text')}
                            </li>
                        </ul>
                    </div>

                    <p>
                        {t('cookies.relacion.text.2')} <b>{t('cookies.relacion.text.3')}</b> {t('cookies.relacion.text.4')}
                    </p>

                    <p>
                        {t('cookies.relacion.text.5')}
                    </p>

                    <h4>{t('cookies.responsabilidades.title')}</h4>
                    <p>
                        {t('cookies.responsabilidades.text.1')}
                    </p>
                    <p>
                        {t('cookies.responsabilidades.text.2')}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PoliticaCookies;
