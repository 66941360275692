import API from '../../../../services/api';
import axios from 'axios';

export const sendWorkWithUs = async (data) => {
    return await API.post('/candidatos', data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(res => res.data)
        .catch(error => error.response);
};

export const getDepartamentos = async () => {
    return await API.get('/departamentos').then(res => res.data);
};

export const getOpenPositions = async () => {
    const url = process.env.REACT_APP_API_POSITIONS;
    return await axios({ url }).then(res => res.data);
};
