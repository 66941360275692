import './vendo.scss';
import Hero from './components/Hero';
import VendoCocheForm from './components/VendeCocheForm';
import Features from './components/Feature';
import { sendCarDataForValuation } from '../../shared/services/vende_coche';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function VendeCoche () {
    const { t } = useTranslation();
    const handleSubmitCoche = async (data) => {
        const formData = new FormData();
        formData.append('matricula', data.matricula);
        formData.append('email', data.email);
        formData.append('telefono', data.telefono);
        formData.append('marca_id', data.marca_id);
        formData.append('modelo_id', data.modelo_id);
        formData.append('anyo', data.anyo);
        formData.append('ubicacion', data.ubicacion);
        formData.append('comentario', data.comentario);
        formData.append('captchaValue', data.captchaValue);
        formData.append('km', data.km);

        for (let i = 0; i < data.fotos.length; i++) {
            formData.append('fotos', data.fotos[i]);
        }

        const response = await sendCarDataForValuation(formData);
        if (response) {
            if (response.success) {
                return response.success;
            } else {
                toast.error(response?.data?.error || t('errors.1'));
            }
        } else {
            return false;
        }
    };

    return (
        <div className="w-100 vende position-relative">
            <Hero />

            <VendoCocheForm
                onSubmitCoche={handleSubmitCoche}
            />

            <Features />
        </div>
    );
}
