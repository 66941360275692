import { useTranslation } from 'react-i18next';

import './surveydone.scss';
import SymbolCheck from '../shared/components/SymbolCheck';

const SurveyDone = () => {
    const { t } = useTranslation();
    return (
        <div className='survey-done'>
            <div className='survey-done__container'>
                <SymbolCheck />
                <div className='survey-done__container--text'>
                    <h2 className='survey-done__container--text--h2'>{t('submited-survey.1')}</h2>
                    <h3 className='survey-done__container--text--h3'>{t('submited-survey.2')}</h3>
                </div>
            </div>
        </div>
    );
};

export default SurveyDone;
