import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';

export default function TrabajaConNosotrosForm ({
    onSubmitCV
}) {
    const { t } = useTranslation();
    const recaptcha = useRef();
    const conctactoSchema = yup.object({
        nombre: yup.string().required('El nombre es obligatorio.'),
        email: yup.string().required('El email es obligatorio.').email('Formato de email incorrecto.'),
        telefono: yup.string().required('El teléfono es obligatorio.'),
        departmento_id: yup.number().integer().test('departamento', 'El departamento es obligatorio.', value => value !== 0),
        mensaje: yup.string().required('El mensaje es obligatorio.'),
        cv: yup.mixed().required('El CV es obligatorio.')
    }).required();

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        setError,
        clearErrors
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(conctactoSchema),
        defaultValues: {
            nombre: '',
            email: '',
            telefono: '',
            departmento_id: 0,
            mensaje: '',
            cv: ''
        },
        shouldUnregister: true
    });

    const departamentos = JSON.parse(localStorage.getItem('departamentos'));
    const [condiciones, setCondiciones] = useState(false);
    const [departmento, setDepartmento] = useState(0);

    const handleChangeCondiciones = (e) => { setCondiciones(e.target.checked); };
    const handleChangeDep = (e) => { setDepartmento(parseInt(e.target.value)); };

    const onSubmit = async (data, e) => {
        e.preventDefault();
        if (departmento === 0) {
            return setError('departmento_id', { type: 'required', message: 'El departamento es obligatorio.' });
        }
        const captchaValue = recaptcha.current.getValue();
        if (!captchaValue) return toast.error(t('errors.captcha'));
        const response = await onSubmitCV({ ...data, captchaValue });
        if (response) {
            setTelefonoValue('');
            reset();
        }
    };

    useEffect(() => {
        if (departmento !== 0) clearErrors('departmento_id');
    }, [departmento]);

    const [telefonoValue, setTelefonoValue] = useState('');

    const handleTelefonoChange = (event) => {
        let inputValue = event.target.value;
        if (!/^[0-9()+-]*$/.test(inputValue)) {
            inputValue = inputValue.slice(0, -1);
        }
        setTelefonoValue(inputValue);
    };

    return (
        <div className="d-flex flex-wrap flex-md-nowrap trabaja-con-nosotros-form">
            <div className='trabaja-con-nosotros-form__titles'>
                <h5 className='trabaja-con-nosotros-form__titles--title'>{t('trabaja.form.title')}</h5>
                <p className='trabaja-con-nosotros-form__titles--subtitle'>{t('trabaja.form.subtitle')}</p>
            </div>
            <form className="form w-100" onSubmit={handleSubmit(onSubmit)}>
                <div className="w-100 d-flex flex-column">
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            {...register('nombre', { maxLength: 50 })}
                            placeholder={t('trabaja.form.placeholder.1')}
                            defaultValue={''} />

                        <div className="form-invalid">
                            {(errors.nombre) &&
                                <div className="w-100 mt-2">
                                    <FontAwesomeIcon icon={faTimesCircle} size="1x" className="action me-1" color="#dc3545" />
                                    {t('trabaja.form.errors.nombre')}
                                </div>
                            }
                        </div>
                    </div>

                    <div className="form-group">
                        <input
                            type="email"
                            className="form-control"
                            placeholder={t('trabaja.form.placeholder.2')}
                            {...register('email', { required: true })}
                            defaultValue={''} />

                        <div className="form-invalid">
                            {(errors.email) &&
                                <div className="w-100 mt-2">
                                    <FontAwesomeIcon icon={faTimesCircle} size="1x" className="action me-1" color="#dc3545" />
                                    {t(`trabaja.form.errors.${errors.email.message}`)}
                                </div>
                            }
                        </div>
                    </div>

                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            {...register('telefono', { maxLength: 20, minLength: 9, required: true })}
                            placeholder={t('trabaja.form.placeholder.3')}
                            value={telefonoValue}
                            maxLength={20}
                            minLength={9}
                            onChange={handleTelefonoChange} />

                        <div className="form-invalid">
                            {(errors.telefono) &&
                                <div className="w-100 mt-2">
                                    <FontAwesomeIcon icon={faTimesCircle} size="1x" className="action me-1" color="#dc3545" />
                                    {t('trabaja.form.errors.tlf')}
                                </div>
                            }
                        </div>
                    </div>

                    <div className="form-group">
                        {departamentos && departamentos.length > 0 && (
                            <select
                                {...register('departmento_id')}
                                value={departmento}
                                onChange={handleChangeDep}
                            >
                                <option value="0" disabled>{t('trabaja.form.placeholder.4.title')}</option>
                                {departamentos.map(dep => (
                                    <option key={dep.id} value={dep.id}>
                                        {t(`trabaja.form.placeholder.4.${dep.id}`)}
                                    </option>
                                ))}
                            </select>
                        )}

                        <div className="form-invalid">
                            {(errors.departmento_id) &&
                                <div className="w-100 mt-2">
                                    <FontAwesomeIcon icon={faTimesCircle} size="1x" className="action me-1" color="#dc3545" />
                                    {t('trabaja.form.errors.dpt')}
                                </div>
                            }
                        </div>
                    </div>

                    <div className="form-group">
                        <textarea
                            type="text"
                            placeholder={t('trabaja.form.placeholder.5')}
                            className="form-control"
                            {...register('mensaje', { required: true })}
                            defaultValue={''}></textarea>

                        <div className="form-invalid">
                            {(errors.mensaje) &&
                                <div className="w-100 mt-2">
                                    <FontAwesomeIcon icon={faTimesCircle} size="1x" className="action me-1" color="#dc3545" />
                                    {t('trabaja.form.errors.msg')}
                                </div>
                            }
                        </div>
                    </div>

                    <div className="form-group">
                        <h5 className="pb-3">{t('trabaja.form.cv')}</h5>
                        <div>
                            <input
                                id="file-cv"
                                type="file"
                                className="form-control"
                                placeholder={t('trabaja.form.cv')}
                                {...register('cv', { required: true })}
                                defaultValue={''} />
                        </div>

                        <div className="form-invalid">
                            {(errors.cv) &&
                                <div className="w-100 mt-2">
                                    <FontAwesomeIcon icon={faTimesCircle} size="1x" className="action me-1" color="#dc3545" />
                                    {t('trabaja.form.errors.cv')}
                                </div>
                            }
                        </div>
                    </div>

                    <div className='w-100 d-flex align-items-center'>
                        <div className="form-check">
                            <input
                                type="checkbox"
                                name="condiciones"
                                id="condiciones"
                                className='form-check-input'
                                onChange={handleChangeCondiciones}
                                defaultChecked={false} />
                            <small className="form-check-label" htmlFor="condiciones">
                                {t('trabaja.form.checkbox.1')}{' '}
                                <Link to={'/politica-de-privacidad'} title="Política de privacidad" className="fw-bold">
                                    {t('trabaja.form.checkbox.2')}
                                </Link>{' '}
                                {t('trabaja.form.checkbox.3')}{' '}
                                <Link to={'/condiciones-generales'} title="Condiciones generales" className="fw-bold">
                                    {t('trabaja.form.checkbox.4')}
                                </Link>
                            </small>
                        </div>
                    </div>
                </div>
                <div className='w-100 d-flex align-items-center justify-content-center pt-3'>
                    <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                </div>
                <div className="w-100 d-flex flex-column form-button mt-4">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={condiciones === false}
                    >{t('trabaja.form.button')}</button>
                </div>
            </form>
        </div>
    );
}
