import API from '../../../../services/api';
import { headers } from '../../../shared/helpers/functionalities';

export const getVentaInfo = async (ventaId) => {
    return await API.get('/incidents/sale-info/' + ventaId)
        .then(res => res.data);
};

export const getVentaInfoVO = async (ventaId) => {
    return await API.get('/incidents-vo/sale-info/' + ventaId)
        .then(res => res.data);
};

export const createIncidents = async (data) => {
    return await API.post('/incidents', data, headers)
        .then(res => res.data)
        .catch(error => error.message);
};

export const createIncidentsVO = async (data) => {
    return await API.post('/incidents-vo', data, headers)
        .then(res => res.data)
        .catch(error => error.message);
};
