import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getPost } from '../../../../shared/services/blog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import recambios from '../../../../../../images/eco.png';
import whatsapp from '../../../../../../images/rrss/whatsapp.png';
import facebook from '../../../../../../images/rrss/facebook.png';
import linkedin from '../../../../../../images/rrss/linkedin.png';
import './post_detail.scss';

export default function PostDetail () {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [post, setPost] = useState([]);
    const { slug } = useParams();
    const gradient = 'linear-gradient(0deg, rgba(33, 87, 50, 0.6), rgba(33, 87, 50, 0.6)), linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))';

    useEffect(() => {
        const getPostDetail = async () => {
            try {
                setIsLoading(true);
                const response = await getPost(slug);

                if (response.success) {
                    setPost(response.posts);
                    setIsLoading(false);
                } else {
                    throw new Error('Error en la llamada');
                }
            } catch (error) {
                console.error(error);
            }
        };
        getPostDetail();
    }, [slug]);

    return (
        <>
            {isLoading
                ? (
                    <div className="seccion w-100 post-detail mb-5 blog">
                        <div className="container d-flex flex-column align-items-center">
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center my-5 py-5">
                                <div className="my-5 py-5">
                                    <img src={recambios} width={25} height={25} alt="Loading" className="loading" />
                                    <span className="ms-3">Cargando..</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                : (
                    <div className="w-100 post-detail">
                        <div className="post-img d-flex align-items-end justify-content-center" style={{
                            backgroundImage: `${gradient}, url(${post._embedded['wp:featuredmedia'] ? post._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url : '/images/placeholder-image.png'})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}>
                            <div className="post-title">
                                <h1>{post.title?.rendered} </h1>
                            </div>
                        </div>

                        <div className="container d-flex post-detail-wrapper">
                            <div className="col-12 col-md-2 post-detail-info px-5">
                                <span className="separador-mb"></span>
                                <div className="container-fecha-por">
                                    <div className="fecha">
                                        <h1 className="day">{format(new Date(post?.date), 'dd')}</h1>
                                        <span>
                                            <p className="month">{format(new Date(post?.date), 'MMMM')}</p>
                                            <p className="year">{format(new Date(post?.date), 'yyyy')}</p>
                                        </span>
                                    </div>

                                    <p className="my-3 por">Por: <span className="fw-bold">{post._embedded?.author[0]?.name}</span></p>
                                </div>
                                <div className="w-100 rrss">
                                    <p>Compartir</p>

                                    <div className="w-100 d-flex flex-column align-items-start rrss-wrapper mt-3">
                                        <a href={`https://api.whatsapp.com/send?text=${window.location.href}`} target="_blank" rel="noreferrer">
                                            <span className="rss-btn">
                                                <img src={whatsapp} alt="Compartir por WhatsApp" />
                                            </span>
                                        </a>

                                        <a target="_blank" rel="noreferrer" href={`http://www.facebook.com/sharer.php?u=${window.location.href}`}>
                                            <span className="rss-btn">
                                                <img src={facebook} alt="Compartir por Facebook" />
                                            </span>
                                        </a>

                                        <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`} target="_blank" rel="noreferrer">
                                            <span className="rss-btn">
                                                <img src={linkedin} alt="Compartir por LinkedIn" />
                                            </span>
                                        </a>

                                    </div>
                                </div>
                                <div className="back d-flex align-items-center justify-content-center btn-mobile">
                                    <button type="button" className="btn btn-outline-primary" onClick={() => navigate('/blog', { replace: true })}>
                                        <FontAwesomeIcon icon={faArrowLeftLong} size="1x" color="#215732" />
                                        Volver al blog
                                    </button>
                                </div>
                            </div>

                            <div className="col-12 col-md-10">
                                <div className="post-detail-content">
                                    <div dangerouslySetInnerHTML={{ __html: post.content.rendered }}></div>
                                </div>

                                <div className="back d-flex align-items-center justify-content-center btn-desktop">
                                    <button type="button" className="btn btn-outline-primary" onClick={() => navigate('/blog', { replace: true })}>
                                        <FontAwesomeIcon icon={faArrowLeftLong} size="1x" color="#215732" />
                                        Volver al blog
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
        </>
    );
}
